import "./App.css";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import ForgotPassword from "./pages/forgotpassword";
import Verification from "./pages/verification";
import Dashboard from "./pages/dashboard";
import Files from "./pages/files";
import History from "./pages/history";
import Newchat from "./pages/newchat";
import Layout from "./pages/layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SettingPopup from "./pages/profilesettings";
import SetNewPassword from "./pages/setnewpassword";
import PreviousChats from "./pages/previouschats";
import ProtectedRoute from "./components/utils/ProtectedRoutes";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/setnewpassword" element={<SetNewPassword />} />
          <Route path="/previouscharts" element={<PreviousChats />} />

          {/* Routes with Layout */}
          <Route element={<ProtectedRoute />}>
          <Route path="/newchat" element={
            <Layout><Newchat /></Layout>
            
            } />

            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/files"
              element={
                <Layout>
                  <Files />
                </Layout>
              }
            />
            <Route
              path="/history"
              element={
                <Layout>
                  <History />
                </Layout>
              }
            />
            <Route
              path="/profilesettings"
              element={
                <Layout>
                  <SettingPopup />
                </Layout>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
