import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import SetNewPassword from '../components/Setnewpassword/Content_Setpassword';
import MainContent from "../components/Setnewpassword/Frame1";
import { lightModeTheme, darkModeTheme } from "../pages/theme";
import { useTheme } from "styled-components";


const SetNewPasswordRoot = styled.div`
  width: 100%;
  height:100vh;
  position: relative;
background-color: ${({ theme }) => theme.backgroundColors.body};
  color: ${({ theme }) => theme.color};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 45px 142px 45px 620px;
  box-sizing: border-box;
  gap: 3.9px;
  line-height: normal;
  letter-spacing: normal;
  @media screen and (max-width: 975px) {
    padding-left: 40px;
    padding-right: 71px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 700px) {
    gap: 15px;
    padding-left: 15px;
    padding-right: 35px;
    box-sizing: border-box;
  }
`;
const App = () => {
  

  return (
  
        <SetNewPasswordRoot >
            <SetNewPassword className="set-new-password"  />
            <MainContent />
        </SetNewPasswordRoot>
    
  );
};

export default App;
