import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { userContext } from "../components/Contexts/userContext";
import { useTheme } from "styled-components";
const MainFileContainer = styled.div`
  padding: 50px 30px 30px 30px;
  display: flex;
  align-content: center;
   flex-wrap: wrap;
`;
 
const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 180px;
  background: ${({ theme }) => theme.backgroundColors.newchatText};
  padding: 10px 10px 10px 15px;
  margin: 20px;
  border-radius:10px;
`;
const FileHeadContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FileTitle = styled.h4`
  font-family: Geist;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.font.primary};
 
`;
const FileTypeImage = styled.img`
  width: 14px;
  height: 18.72px;
  margin-right: 10px;
`;
const FileTypeBodyImage = styled.img`
  width: 22.23px;
  height: 29.72px;
  background: ${({ theme }) => theme.backgroundColors.popup};
  padding:50px 70px 50px 70px;
  margin-bottom: 20px;
`;
const LoadingContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 90vh;
`;
const ImageContainer = styled.img`
  width: 150px;
  height: 100px;
`;
const Files = () => {
  const themes = useTheme();
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
 const {userDetails} = useContext(userContext);
 const loadingGif = themes.images.loading;
 
 
  const getFilesByToken = async () => {
    const url = "http://129.146.99.175:4000/api/minio/getFilesByToken";
    const OPTIONS = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token:
          userDetails?.userToken,
      }),
    };
    try {
      const response = await fetch(url, OPTIONS);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
     setFileData(data.files)
     console.log(fileData)
    } catch (error) {
      console.log("Fetch error: ", error);
    }finally {
      setLoading(false); // Set loading to false after fetch completes
    }
  };
 
  const getImageByFileType =(fileType)=>{
 
    switch(fileType){
      case ".pdf":
       return "/images/pdflogo.svg";
       case ".csv":
        return "/images/csvfile.png";
        case ".txt":
        return "/images/textfile.svg";
        default:
          return "/images/unknown-file.png";
      }
 
    };
 
  useEffect(() => {
    getFilesByToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <>
    {loading ? (
        <LoadingContainer>
                        <ImageContainer src={loadingGif} alt="loading"/>
          
        </LoadingContainer>
      ) : (
   <MainFileContainer>
        {fileData.map((file, index) => (
          <FileContainer key={index}>
            <FileHeadContainer>
              <FileTypeImage src={getImageByFileType(file.fileType)} alt="filetype" />
              <FileTitle>{file.baseFileName}</FileTitle>
            </FileHeadContainer>
            <FileTypeBodyImage src={getImageByFileType(file.fileType)} alt="filebodytype" />
          </FileContainer>
        ))}
      </MainFileContainer>
      )
    }
    </>
  );
};
 
export default Files;