import styled from "styled-components";
import PropTypes from "prop-types";
import ThemeToggler from "../ThemeToggler/themetoggler";
import { useTheme } from "styled-components";


const GroupIcon = styled.img`
  height: 43.1px;
  width: 43.1px;
  position: relative;
  `;
const FrameChild = styled.img`
  align-self: stretch;
  height: 34px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;
const StructureInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs-3) 0px 0px;
`;
const Structure = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs-7);
`;
// const LogobarChild = styled.img`
//   height: 55px;
//   width: 55px;
//   position: relative;
// `;
const LogobarRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
`;

const Logobar = ({ className = "" }) => {
  const theme = useTheme();
  const groupLogo = theme.images.groupLogo;
  const groupLogoName = theme.images.groupLogoName;
  return (
    <LogobarRoot className={className}>
      {/* <Structure>
        <GroupIcon loading="lazy" alt="" src={groupLogo}  />
        <StructureInner>
          <FrameChild loading="lazy" alt="" src={groupLogoName} />
        </StructureInner>
      </Structure> */}
      {/* <LogobarChild loading="lazy" alt="" src="/images/theme-light.svg" /> */}
      <ThemeToggler/>
    </LogobarRoot>
  );
};

Logobar.propTypes = {
  className: PropTypes.string,
};

export default Logobar;
