import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { userContext } from "../Contexts/userContext";
import { themeContext } from "../Contexts/themeContext";
import { useTheme } from "styled-components";
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.hover};
  }
`;
const Logo = styled.img`
  height: 32px;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-top: 30px;
`;
const Logocollapsed = styled.img`
  height: 27px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-top: 30px;
`;

const Iconset = styled.img`
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 8px;
`;
const IconsetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.collapsed ? "center" : "flex-start")};
  justify-content: flex-start;
  padding: 2px 0px 0px;
`;
const Text1 = styled.a`
  text-decoration: none;
  position: relative;
  font-family:  var(--FontfamilyInter);
  font-size: var(--font-size-base);
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  display: inline-block;
  min-width: 73px;
`;
const Text2 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Button1 = styled.button`
  cursor: pointer;
  border: none;
  padding: 8px 70px;
  background-color: #000000;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const Button1Compact = styled.button`
  cursor: pointer;
  border: none;
  padding: 5px 5px;
  background-color: #000000;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 8px;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 20px;
`;
const Iconset1 = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 8px;
  min-height: 24px;
`;
const Dashboard = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 20px;
  color: inherit;
  display: inline-block;
  min-width: 73px;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
`;
const Icontext = styled.div`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;

// const Iconset2 = styled.img`
//   margin-left: -37px;
//   height: 24px;
//   width: 24px;
//   position: relative;
//   border-radius: 8px;
//   min-height: 24px;
// `;
// const Files = styled.a`
//   text-decoration: none;
//   position: relative;
//   line-height: 20px;
//   color: inherit;
//   display: inline-block;
//   min-width: 31px;
// `;
// const Icontext1 = styled.div`
//   flex: 1;
//   border-radius: 8px;
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: flex-start;
//   padding: 0px 36px;
//   gap: 13px;
// `;
const Files1 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  width: 242px;
`;
const FilesCollapse = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-center;
  justify-content: flex-center;
  padding: 12px;
`;

// const History1 = styled.a`
//   text-decoration: none;
//   position: relative;
//   line-height: 20px;
//   color: inherit;
//   display: inline-block;
//   width: 242px;
// `;
const FrameChild = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.backgroundColors.userprofile};
`;
const FrameItem = styled.img`
  height: 43px;
  width: 43px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1;
`;
const JohnJacob = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  min-width: 103px;
  z-index: 1;
`;
const JohnJacobWrapper = styled.div`
  font-family: Geist;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0px 0px;
`;
const RectangleParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 14px 6px;
  position: relative;
  gap: 19px;
`;
const Settings = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SettingsPanel = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 11px 0px 10px;
  font-size: 16px;
  color: ${({ theme }) => theme.font.primary};
  font-family: Geist;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  cursor: pointer;
`;
const SettingsPanelCollapsed = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.font.primary};
  font-family: Geist;
  transform: translateX(-20%);
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  cursor: pointer;
`;
const SidebarRoot = styled.div`
   height: 100%;
  width: ${(props) => (props.collapsed ? "80px" : "303px")};
  background-color: ${({ theme }) => theme.backgroundColors.sidebarBody};
  border-right: 1px solid ${({ theme }) => theme.border.sidebar};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 19px 55px;
  gap: 12px;
  text-align: left;
  font-size: 14px;
  color: ${({ theme }) => theme.font.primary};
  font-family: Geist;
  transition: width 0.3s;
  @media screen and (max-width: 1210px) {
    // display: none;
    padding-bottom: 36px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 750px) {
    padding-bottom: 23px;
    box-sizing: border-box;
  }
`;
const Popup = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 243px;
  height: 293px;
  background-color:${({ theme }) => theme.backgroundColors.newchatText}
  display: flex;
  margin-left: 25px;
  margin-bottom: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
const PopupItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  text-align: start;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.hover};
  }
`;
const PopupIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;
const PopupText = styled.div`
  color: ${({ theme }) => theme.font.primary};
  margin-top: 6px;
  font-family: var(--FontfamilyInter);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
`;
const PopupItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ToggleButton = styled.button`
  position: absolute;
  top: 500px;
  left: ${(props) => (props.collapsed ? "80px" : "303px")};
  transform: translateX(-50%);
  background-color: #1c1c1c;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
`;

const Sidebar = ({ className = "",setIsCollapsed,isCollapsed }) => {
  const { theme, setTheme } = useContext(themeContext);
  const themes = useTheme();

  const dashboard = themes.images.dashboard;
  const files = themes.images.files;
  const history = themes.images.history;
  const kashikaTextLogo = themes.images.fullLogo;
  const kashikaLogo = themes.images.groupLogo;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  // const [userImg, setUserImg] = useState("/images/membericon.png");

  const { userDetails, logout, userImg, setUserImg } = useContext(userContext);

  // const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1210);

  const handleSettingsClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleItemClick = (item) => {
    setIsPopupOpen(false);
    switch (item) {
      case "Clear conversations":
        console.log("Clear conversations clicked");
        break;
      case "Dark mode":
        theme === "light" ? setTheme("dark") : setTheme("light");
        break;
      case "Light mode":
        theme === "dark" ? setTheme("light") : setTheme("dark");
        break;
      case "My account":
        console.log("My account clicked");
        navigate("/profilesettings");
        break;
      case "Updates & FAQ":
        console.log("Updates & FAQ clicked");
        break;
      case "Log out":
        console.log("Log out clicked");
        logout();
        navigate("/");
        break;
      default:
        break;
    }
  };

  const handleToggleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getProfilePic = async () => {
    try {
      const url = "http://129.146.99.175:4000/api/users/getuserbytoken";
      const OPTIONS = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: userDetails?.userToken,
        }),
      };
      const response = await fetch(url, OPTIONS);
      const data = await response.json();
      console.log(data);
      const profilePicUrl = data?.user?.profile_url || "/images/membericon.png"; // Fallback to default image
      setUserImg(profilePicUrl);
    } catch (error) {
      console.log(error);
      setUserImg("/images/membericon.png"); // Fallback to default image on error
    }
  };

  const getIconSrc = (index) => {
    const lightThemeIcons = [
      "/images/icons/dark/0.svg",
      "/images/icons/dark/1.svg",
      "/images/icons/dark/2.svg",
      "/images/icons/dark/3.svg",
      "/images/icons/dark/4.svg",
    ];
    const darkThemeIcons = [
      "/images/icons/light/0.svg",
      "/images/icons/light/1.svg",
      "/images/icons/light/2.svg",
      "/images/icons/light/3.svg",
      "/images/icons/light/4.svg",
    ];
    return theme === "light" ? lightThemeIcons[index] : darkThemeIcons[index];
  };

  useEffect(() => {
    getProfilePic();
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 1210);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ToggleButton onClick={handleToggleClick} collapsed={isCollapsed}>
        {isCollapsed ? ">" : "<"}
      </ToggleButton>
      <SidebarRoot className={className} collapsed={isCollapsed}>
        {isCollapsed ? (
          <Logocollapsed src={kashikaLogo} alt="Logocollapsed" />
        ) : (
          <Logo src={kashikaTextLogo} alt="Logo" />
        )}

        <StyledLink to="/newchat" style={{ backgroundColor: "transparent" }}>
          <ButtonPanel>
            {isCollapsed ? (
              <Button1Compact title="New Chat">
                <IconsetWrapper>
                  <Iconset alt="" src="/images/newchat.png" />
                </IconsetWrapper>
              </Button1Compact>
            ) : (
              <Button1>
                <IconsetWrapper>
                  <Iconset alt="" src="/images/newchat.png" />
                </IconsetWrapper>
                <Text2>
                  <Text1>New chat</Text1>
                </Text2>
              </Button1>
            )}
          </ButtonPanel>
        </StyledLink>
        {isCollapsed && (
          <StyledLink to="/dashboard">
            <FilesCollapse>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "Dashboard" : ""}
                  loading="lazy"
                  alt=""
                  src={dashboard}
                />
              </Icontext>
            </FilesCollapse>
          </StyledLink>
        )}
        {!isCollapsed && (
          <StyledLink to="/dashboard">
            <Files1>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "Dashboard" : ""}
                  loading="lazy"
                  alt=""
                  src={dashboard}
                />
                <IconsetWrapper>
                  <Text2>
                    <Dashboard>Dashboard</Dashboard>
                  </Text2>
                </IconsetWrapper>
              </Icontext>
            </Files1>
          </StyledLink>
        )}
        {isCollapsed && (
          <StyledLink to="/files">
            <FilesCollapse>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "Files" : ""}
                  loading="lazy"
                  alt="files"
                  src={files}
                />
              </Icontext>
            </FilesCollapse>
          </StyledLink>
        )}
        {!isCollapsed && (
          <StyledLink to="/files">
            <Files1>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "Files" : ""}
                  loading="lazy"
                  alt="files"
                  src={files}
                />
                <IconsetWrapper>
                  <Text2>
                    <Dashboard>Files</Dashboard>
                  </Text2>
                </IconsetWrapper>
              </Icontext>
            </Files1>
          </StyledLink>
        )}

        {isCollapsed && (
          <StyledLink to="/history">
            <FilesCollapse>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "History" : ""}
                  loading="lazy"
                  alt="history"
                  src={history}
                />
              </Icontext>
            </FilesCollapse>
          </StyledLink>
        )}
        {!isCollapsed && (
          <StyledLink to="/history">
            <Files1>
              <Icontext>
                <Iconset1
                  title={isCollapsed ? "History" : ""}
                  loading="lazy"
                  alt="History"
                  src={history}
                />
                <IconsetWrapper>
                  <Text2>
                    <Dashboard>History</Dashboard>
                  </Text2>
                </IconsetWrapper>
              </Icontext>
            </Files1>
          </StyledLink>
        )}

        {!isCollapsed ? (
          <SettingsPanel onClick={handleSettingsClick}>
            <Settings>
              <RectangleParent>
                <FrameChild />
                <FrameItem loading="lazy" alt="" src={userImg} />
                <JohnJacobWrapper>
                  <JohnJacob>{userDetails?.userName}</JohnJacob>
                </JohnJacobWrapper>
              </RectangleParent>
            </Settings>
          </SettingsPanel>
        ) : (
          <SettingsPanelCollapsed onClick={handleSettingsClick}>
            <Settings>
              <RectangleParent>
                <FrameChild />
                <FrameItem loading="lazy" alt="" src={userImg} />
              </RectangleParent>
            </Settings>
          </SettingsPanelCollapsed>
        )}
        {isPopupOpen && (
          <Popup>
            {[
              "Clear conversations",
              theme === "light" ? "Dark mode" : "Light mode",
              "My account",
              "Updates & FAQ",
              "Log out",
            ].map((item, index) => (
              <PopupItem key={index} onClick={() => handleItemClick(item)}>
                <PopupItemContainer>
                  <PopupIcon src={getIconSrc(index)} alt={`Icon ${index}`} />
                  <PopupText>{item}</PopupText>
                </PopupItemContainer>
              </PopupItem>
            ))}
          </Popup>
        )}
      </SidebarRoot>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  toggleTheme: PropTypes.func.isRequired,
};

export default Sidebar;
