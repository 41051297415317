import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import InputTrio1 from "./email-input";
import InputTrio from "./password-input";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import usePasswordVisibility from "../Hooks/usePasswordVisibility";

const FrameChild = styled.div`
  width: 505px;
  height: 701px;
  position: relative;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  // border: 0.5px solid var(--color-gray-100);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;

const Welcome = styled.h3`
  margin: 0;
  position: relative;
  font-size: var(--font-size-6xl);
  font-weight: 300;
  font-family: var(--font-geist);
  line-height: 31px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-xl);
  }
`;
const SignIn = styled.h2`
  margin: 0;
  position: relative;
  font-size: var(--font-size-12xl);
  font-weight: 500;
  line-height: 38.44px;
  font-family: var(--font-geist);
  display: inline-block;
  min-width: 99px;
  z-index: 1;
  @media screen and (max-width: 825px) {
    font-size: var(--font-size-6xl);
  }
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
  }
`;
const LoremIpsumIs = styled.div`
  align-self: stretch;
  position: relative;
  font-family: var(--font-geist);
  font-weight:400;
  font-size: var(--font-size-base);
  line-height:19.84px
  z-index: 1;
`;
const Credentials = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  font-size: var(--font-size-12xl);
  // padding-bottom: var(--padding-17xl);
`;
const RememberCheckbox = styled.input`
  margin: 0;
  height: 15px;
  width: 15px;
  position: relative;
  border: ${({ theme }) => theme.border.rememberMe};
  appearance: none;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  cursor: pointer;
  z-index: 1;
`;
const RememebrMe = styled.div`
  position: relative;
  font-weight: 300;
  display: inline-block;
  min-width: 80px;
  z-index: 1;
  font-family: Geist;
  font-size: var(--font-size-xs);
  font-weight: 300;
  line-height: 14.88px;
`;
const RememberToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const RememberToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0px 0px;
`;
const ForgotPassword = styled.div`
  position: relative;
  font-family: Geist;
  font-size: var(--font-size-xs);
  font-weight: 600;
  line-height: 14.88px;
  color: ${({ theme }) => theme.font.minorText2};
  display: inline-block;
  min-width: 108px;
  cursor: pointer;
  z-index: 1;
`;
const RememberForgot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
`;
const PasswordField = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px;
  //  padding: 0px 0px var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-4xl);
  max-width: 100%;
`;
const RectangleDiv = styled.div`
  height: 57px;
  width: 423px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-black);
  display: none;
  max-width: 100%;
`;
const Login = styled.div`
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-geist);
  line-height: 19.84px;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  min-width: 42px;
  cursor: pointer;
  z-index: 1;
`;
const GroupButton = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-mid) var(--padding-xl) var(--padding-xl);
  background-color: ${({ theme }) => theme.backgroundColors.buttonColor};
  flex: 1;
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 1;
  &:hover {
    background-color: var(--color-darkslategray);
  }
`;
const FrameWrapper = styled.div`
  width: 423px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 0px 0px var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
`;
const DonyHaveAn = styled.span`
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 300;
  line-height: 19.84px;
`;
const Register = styled.span`
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 600;
  line-height: 19.84px;
  color: ${({ theme }) => theme.font.primary};
  white-space: pre-wrap;
`;
const DonyHaveAnContainer = styled.div`
  flex: 1;
  position: relative;
  z-index: 1;
`;
const DonyHaveAnAccountRegistWrapper = styled.div`
  width: 406px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-58xl);
  box-sizing: border-box;
  max-width: 100%;
  color: ${({ theme }) => theme.font.smallText};
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const RectangleParent = styled.form`
  margin: 0;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  border: ${({ theme }) => theme.border.primary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 13px 35px 27px;
  gap: 35px;
  margin-top: 50px;
  width: 100%;

  @media screen and (max-width: 450px) {
    padding: 10px 15px;
  }
`;
const Layer1Icon = styled.img`
  align-self: stretch;
  height: 487px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;
const Footer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-78xl) 0px 0px;
  box-sizing: border-box;
  min-width: 538px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    min-width: 100%;
  }
  @media screen and (max-width: 825px) {
    padding-top: var(--padding-44xl);
    box-sizing: border-box;
  }
`;
const MainContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-width:505px;
  @media screen and (max-width: 825px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 450px) {
    padding: 0 10px;
  }
`;

const ErrorMsg = styled.div`
  // padding-bottom: var(--padding-17xl);
  font-size: var(--font-size-xs);
  color: var(--color-error);
`;

const ResponseError = styled.div`
  font-size: var(--font-size-xs);
  color: var(--color-error);
`;
const FrameChild2 = styled.img`
  height: 43px;
  width: 210px;
`;
const MainContent = ({ className = "" }) => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const [userLogin, setUserLogin] = useState(initialValues);
  console.log("userLogin", userLogin);
  const [loginErrors, setLoginErrors] = useState({});
  const [responseErrors, setResponseErrors] = useState({});
  console.log("response errors", responseErrors);
  const [isLogin, setIsLogin] = useState(false);

  const theme = useTheme();
  const { userDetails, setUserDetails } = useContext(userContext);
  const groupLogoName = theme.images.fullLogo;

  const typingImage = theme.images.typingImage;
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setLoginErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const clearResponseErrors = () => {
    setResponseErrors({});
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = loginValidation(userLogin);
    setLoginErrors(errors);
    setIsLogin(true);
  };
  useEffect(() => {
    const submitForm = async () => {
      if (Object.keys(loginErrors).length === 0 && isLogin) {
        const url = "http://129.146.99.175:4000/api/users/login";
        const OPTIONS = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userLogin),
        };
        try {
          const response = await fetch(url, OPTIONS);
          if (!response.ok) {
            console.log("Error", response.status, response.statusText);
          }
          const data = await response.json();
          console.log("Response Data", data);
          if (response.status !== 200) {
            console.log(response);
            console.log(data);
            setResponseErrors(data);
          } else {
            setUserDetails((prev) => ({
              ...prev,
              userName: data.name,
              userEmailId: data.email,
              isLoggedin: true,
              isUserAdmin: data.isAdmin,
              userId: data.id,
              userToken: data.token,
            }));
            navigate("/dashboard");
            localStorage.setItem(
              "userDetails",
              JSON.stringify({
                userName: data.name,
                userEmailId: data.email,
                isLoggedin: true,
                isUserAdmin: data.isAdmin,
                userId: data.id,
                userToken: data.token,
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
        setIsLogin(false); // Reset isLogin state
      }
    };

    submitForm();
  }, [loginErrors, isLogin, userLogin, userDetails, setUserDetails]);

  const loginValidation = (userLogin) => {
    const errors = {};
    if (!userLogin.email) {
      errors.email = "Email is Required";
    }
    if (!userLogin.password) {
      errors.password = "Password is Required";
    }

    return errors;
  };

  return (
    <MainContentRoot className={className}>
      <FrameChild2 loading="lazy" alt="" src={groupLogoName} />

      <RectangleParent onSubmit={handleLogin}>
        <FrameChild />
        <Welcome>Welcome !</Welcome>
        <Credentials>
          <SignIn>{`Sign in `}</SignIn>
          <LoremIpsumIs>{`Lorem Ipsum is simply `}</LoremIpsumIs>
        </Credentials>

        <InputTrio1
          email="Email"
          name="email"
          enterYourEmailPlaceholder="Enter your email"
          value={userLogin.email}
          onChange={handleChange}
          loginErrors={loginErrors.email}
          onFocus={clearResponseErrors}
        />
        
        {/* <ErrorMsg>{loginErrors.email}</ErrorMsg> */}
        <PasswordField>
          <InputTrio
            password="Password"
            name="password"
            frame9Placeholder="Enter your Password"
            value={userLogin.password}
            onChange={handleChange}
            loginErrors={loginErrors.password}
            onFocus={clearResponseErrors}
           
          />

          <RememberForgot>
            <RememberToggleWrapper>
              <RememberToggle>
                <RememberCheckbox type="checkbox" />
                <RememebrMe>Remember me</RememebrMe>
              </RememberToggle>
            </RememberToggleWrapper>
            <Link to="/forgotpassword">
              <ForgotPassword>Forgot Password ?</ForgotPassword>
            </Link>
          </RememberForgot>
        </PasswordField>
        <FrameWrapper type="submit">
          <GroupButton>
            <RectangleDiv />
            <Login>Login</Login>
          </GroupButton>
        </FrameWrapper>

        {responseErrors.message && (
          <ResponseError>{responseErrors.message}</ResponseError>
        )}

        <DonyHaveAnAccountRegistWrapper>
          <DonyHaveAnContainer>
            <DonyHaveAn>Don’t have an Account ?</DonyHaveAn>
            <Link to="/signup">
              <Register> Register</Register>
            </Link>
          </DonyHaveAnContainer>
        </DonyHaveAnAccountRegistWrapper>
      </RectangleParent>
      
    </MainContentRoot>

  );
};

MainContent.propTypes = {
  className: PropTypes.string,
};

export default MainContent;
