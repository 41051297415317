import styled from "styled-components";
import PropTypes from "prop-types";

const FrameChild = styled.div`
  height: 59px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  border: 0.6px solid #282828;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const EnterYourEmail = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 300;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 17.36px;
  background-color: transparent;
  height: 20px;
  position: relative;

  color: ${({ theme }) => theme.font.primary};
  text-align: left;
  display: inline-block;
  padding: 0;
  z-index: 1;
  @media screen and (max-width: 375px) {
    font-size: 8px;
  }

  width: ${(p) => p.propWidth};
`;

const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: 6px;
  background-color: transparent;
  border: 0.6px solid var(--color-gray-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 27px;
  max-width: 100%;
  @media screen and (max-width: 375px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 414px) {
    margin-top: 20px;
  }
  z-index: 1;
  margin-top: 40px;
`;
const InputTrioRoot = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 7px;
  box-sizing: border-box;
  gap: 13px;
  max-width: 100%;
  text-align: left;
  font-size: 17px;
  @media screen and (max-width: 1024px) {
    width: 334px;
  }
  @media screen and (max-width: 912px) {
    width: 285px;
  }
  color: ${({ theme }) => theme.color};
  font-family: Geist;
`;

const InputTrio1 = ({
  className = "",
  email,
  enterYourEmailPlaceholder,
  name,
  value,
  onChange,
  propWidth,
}) => {
  return (
    <InputTrioRoot className={className}>
      <RectangleParent>
        <FrameChild />
        <EnterYourEmail
          type="email"
          placeholder={enterYourEmailPlaceholder}
          name={name}
          value={value}
          onChange={onChange}
          propWidth={propWidth}
          autoComplete="off"
        />
      </RectangleParent>
    </InputTrioRoot>
  );
};

InputTrio1.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  enterYourEmailPlaceholder: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
};

export default InputTrio1;
