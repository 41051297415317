export const lightModeTheme = {
  mode: "light",
  background: "#fff",
  color: "#151515",
};
export const darkModeTheme = {
  mode: "dark",
  background: "#151515",
  color: "#fff",
};
const lightTheme = {
  backgroundColors: {
    body: "#FFFFFF",
    bodySecondary: "#FFFFFF",
    buttonColor: "#000000",
    hover: "#d9d9d9",
    newchatText: "#f7f9fb",
    sidebarBody: "#FFFFFF",
    popup: "#F7F9FB",
    userprofile: "#f7f9fb",
    chatmessage: "#f7f9fb",
    myAccount: "#F4F4F4",
  },
  images: {
    groupLogo: "/images/groupicon-light.svg",
    groupLogoName: "/images/kashikatext-light.svg",
    fullLogo: "/images/fullLogo-dark.svg",
    typingImage: "/images/typing-light.svg",
    deviceMobile: "/images/devicemobile.svg",
    dashboard: "/images/dashboard-dark.svg",
    files: "/images/files-dark.svg",
    history: "/images/history-dark.svg",
    uploadFile: "/images/upload-dark.svg",
    sendIcon: "/images/send-dark.svg",
    eyeIconInvisible: "/images/eye-invisible-light.svg",
    eyeIconVisible: "/images/eye-visible-light.svg",
    loading: "/loading-light.gif",
    myAccProfileIcon: "/images/profile.svg",
    myAccSupportIcon: "/images/support-light.svg",
    myAccModelSelectIcon: "/images/modelselection-light.svg",
  },
  font: {
    primary: "#000000",
    smallText: "#7D7D7D",
    // minorText1:"#000000",
    minorText2: "#4D4D4D",
    minorText3: "#1C1C1C",
    minorText4: "#1C1C1C66",
    minorText5: "#0000FF",
  },
  border: {
    primary: " 0.5px solid #878787",
    secondary: "0.6px solid #282828",
    borderCodeNormal: "1px solid var(--black-10, #1C1C1C1A)",
    borderCodeActive: "1px solid var(--black-40, #1C1C1C66)",
    sidebar: "#1C1C1C1A",
    rememberMe: "1px solid #000000",
  },
  text: "#000000",
  textSecondary: "#ABABAB",
  boxShadow: "0px 4px 64px 0px #0000000D",
  themeTogglerLogo: "images/Group247.svg",
};

const darkTheme = {
  backgroundColors: {
    body: "#151515",
    bodySecondary: "#000000",
    buttonColor: "#171717",
    hover: "#000000",
    newchatText: "#272727",
    sidebarBody: "#1C1C1C",
    popup: "#000000",
    userprofile: "#000000",
    chatmessage: "#1C1C1C",
    myAccount: "#212121",
  },
  images: {
    groupLogo: "/images/groupicon-dark.svg",
    groupLogoName: "/images/kashikatext-dark.svg",
    fullLogo: "/images/fullLogo-light.svg",
    typingImage: "/images/typing-dark.svg",
    deviceMobile: "/images/devicemobile-dark.svg",
    dashboard: "/images/dashboard-light.svg",
    files: "/images/files-light.svg",
    history: "/images/history-light.svg",
    uploadFile: "/images/upload-light.svg",
    sendIcon: "/images/send-light.svg",
    eyeIconInvisible: "/images/eye-invisible-dark.svg",
    eyeIconVisible: "/images/eye-visible-dark.svg",
    loading: "/loading-dark.gif",
    myAccProfileIcon: "/images/profile.svg",
    myAccSupportIcon: "/images/support-dark.svg",
    myAccModelSelectIcon: "/images/modelselection-dark.svg",
  },
  font: {
    primary: "#FFFFFF",
    smallText: "#E6E6E6",
    // minorText1:"#E6E6E6",
    minorText2: "#E6E6E6",
    minorText3: "#FFFFFF",
    minorText4: "#FFFFFF66",
    minorText5: "#FFFFFF",
  },
  border: {
    primary: "",
    secondary: "0.6px solid #E6E6E6",
    borderCodeNormal: "1px solid #E6E6E699",
    borderCodeActive: "1px solid #E6E6E699",
    sidebar: "#d9d9d9",
    rememberMe: "1px solid #E6E6E6",
  },
  text: "#FAFAFA",
  themeTogglerLogo: "images/Group47.svg",
};

export { lightTheme, darkTheme };
