import Logobar from "../components/Signin/logobar";
import MainContent from "../components/Signin/main-content";
import styled from "styled-components"; 
import { useTheme } from "styled-components";


const MainContentWrapper = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
`;
const Layer1Icon = styled.img`
  align-self: stretch;
  height: 487px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
const FrameParent = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 142px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    gap: 0px;
  }
`;
const LayerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 97px 0px 0px;
  padding-top: 13%;
  box-sizing: border-box;
`;
const SigninRoot = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  justify-content: flex-center;
  padding: 60px 30px;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
`;

const Signin = () => {
  const theme = useTheme();

  const typingImage = theme.images.typingImage;
 
  return (
    <SigninRoot>
      <Logobar />
      <MainContentWrapper>
      <FrameParent>

        <MainContent />
        <LayerWrapper>
            <Layer1Icon loading="lazy" alt="typing-image" src={typingImage} />
          </LayerWrapper>
          </FrameParent>
      </MainContentWrapper>
    </SigninRoot>
  );
};

export default Signin;
