import React from 'react'

function history() {
  return (
    <div>
      <h2>This is history page</h2>
    </div>
  )
}

export default history
