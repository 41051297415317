import styled from "styled-components";
import { lightTheme, darkTheme } from "../../pages/theme";
import { useContext } from "react";
import { themeContext } from "../Contexts/themeContext";

const LogobarChild = styled.img`
  height: 55px;
  width: 55px;
  position: absolute;
  top: 0px;
  right: 30px;
  // @media screen and (max-width: 390px) {
  //      height: 55px;
  //      width: 45px;
  // }
  // @media screen and (max-width: 430px) {
  //      height: 55px;
  //      width: 40px;
  // }
  // @media screen and (max-width: 768px) {
  //      height: 55px;
  //      width: 35px;
  // }
`;

const ThemeToggler = () => {
  const { theme, setTheme } = useContext(themeContext);

  return (
    <LogobarChild
      onClick={() => {
        theme === "light" ? setTheme("dark") : setTheme("light");
      }}
      loading="lazy"
      alt=""
      src={
        theme === "light"
          ? lightTheme.themeTogglerLogo
          : darkTheme.themeTogglerLogo
      }
    />
  );
};

export default ThemeToggler;
