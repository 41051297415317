import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { ThemeContextProvider } from "./components/Contexts/themeContext";

import ThemedApp from "./ThemedApp";

const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log(themeContext);
// console.log(userContext);
root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <ThemedApp />
    </ThemeContextProvider>
  </React.StrictMode>
);
