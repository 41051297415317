import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { passwordResetContext } from "../Contexts/passwordResetContext";
import usePasswordVisibility from "../Hooks/usePasswordVisibility";
import EyeIconToggler from "../EyeIconToggler/eyeIconToggler";

const MainTitle = styled.h2`
  align-self: stretch;
  position: relative;
  font-family: var(--font-geist);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.font.minorText3};
  text-align: center;
  @media screen and (max-width: 450px) {
    font-size: 19px;
    line-height: 26px;
  }
`;

const HaveYouAlready = styled.span`
  color: ${({ theme }) => theme.font.minorText4};
`;

const SignIn = styled.span`
  color: ${({ theme }) => theme.font.minorText5};
`;

const SubTitle = styled.div`
  position: relative;
  letter-spacing: -0.01px;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const Text1 = styled.div`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const Header = styled.div`
  width: 426px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 62px 12px 58px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`;

const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: 6px;
  border: ${({ theme }) => theme.border.secondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  max-width: 100%;
  position: relative;
`;

const PasswordInput = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.font.primary};
  font-weight: 300;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 17.36px;
  outline: none;
`;

const EyeIcon = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => (theme.mode === "dark" ? "#fff" : "#000")};
`;

const StrengthBar = styled.div`
  height: 4px;
  flex: 1;
  position: relative;
  border-radius: 80px;
  background-color: ${({ theme, passwordStrength }) => {
    if (passwordStrength === 0)
      return theme.mode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(28, 28, 28, 0.1)";
    else if (passwordStrength === 2)
      return theme.mode === "dark" ? "#F09610" : "#F09610";
    else if (passwordStrength === 3)
      return theme.mode === "dark" ? "#B63B32" : "#B63B32";
    else if (passwordStrength === 4)
      return theme.mode === "dark" ? "#2387BD" : "#2387BD";
    else if (passwordStrength >= 5 && passwordStrength < 12)
      return theme.mode === "dark"
        ? "rgba(0, 255, 0, 1)"
        : "rgba(0, 255, 0, 1)";
    else if (passwordStrength >= 12)
      return theme.mode === "dark" ? "rgba(0, 225,0,1)" : "rgba(0, 225, 0, 1)";
  }};
`;

const Strip = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const StrengthLabel = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-xs);
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.font.minorText4};
  text-align: left;
`;

const Text2 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StrengthContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
`;

const StrengthIndicator = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 23px 0px 16px;
  box-sizing: border-box;
  max-width: 100%;
`;

const InputFields = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 21.5px;
  max-width: 100%;
`;

const Form = styled.div`
  width: 426px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px 3px;
  box-sizing: border-box;
  max-width: 100%;
`;

const CheckboxIcon = styled.input`
  // margin: 0;
  height: 15px;
  width: 15px;
  position: relative;
  border: ${({ theme }) => theme.border.rememberMe};
  appearance: none;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  cursor: pointer;
  z-index: 1;
`;

const Iconset = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Text3 = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: inline-block;
  min-width: 125px;
`;

const Text4 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Icontext = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const RememberMe = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 19px;
`;

const FrameInner = styled.div`
  height: 57px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #000;
  display: none;
  max-width: 100%;
`;

const Submit = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 19.84px;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 54px;
  z-index: 1;
  cursor: pointer;
`;

const RectangleContainer = styled.button`
  align-self: stretch;
  border: none;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.backgroundColors.buttonColor};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 17px 179px 20px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`;

const Options = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  max-width: 100%;
`;

const MainContentRoot = styled.form`
  width: 680px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  border: 1px solid #878787;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 76px 20px 109px 24px;
  gap: 31px;
  max-width: 100%;
  gap: 17px;
  max-width: 100%;
  @media screen and (max-width: 700px) {
    gap: 15px;
    padding-top: 49px;
    padding-bottom: 90px;
    box-sizing: border-box;
  }
`;

const MainContent = ({ className = "" }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { userPasswordReset } = useContext(passwordResetContext);
  const [password, setPassword] = useState("");
  console.log("password", password);
  const [confirmPassword, setConfirmPassword] = useState("");
  console.log("confirmPassword", confirmPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  console.log("passwordError", passwordError);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0); // Initialize password strength

  const { showPassword, handleShowPassword } = usePasswordVisibility();
  console.log("in main", showPassword, handleShowPassword);

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePassword = (value) => {
    // Check if password has at least 8 characters
    if (value.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    // Check if password contains letters, numbers, and symbols
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    if (!(hasLetter && hasNumber && hasSymbol)) {
      return "Password must contain letters, numbers, and symbols.";
    }
    return "";
  };

  const calculatePasswordStrength = (value) => {
    let strength = 0;
    // Calculate password strength based on criteria
    if (value.length >= 8) strength++;
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    if (hasLetter && hasNumber && hasSymbol) strength++;
    if (value.length >= 10) strength++;
    if (value.length >= 12) strength++; // Additional strength point for length >= 12
    return strength;
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(validatePassword(value));
    setPasswordStrength(calculatePasswordStrength(value));
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (password && password !== value) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isChecked) {
      alert("Please accept the terms before submitting.");
      return;
    }

    const url = "http://129.146.99.175:4000/api/users/updateForgotPassword";
    const passwordoptions = {
      emailToken: userPasswordReset.passwordResetToken,
      newPassword: password,
      confirmPassword: confirmPassword,
    };
    const OPTIONS = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passwordoptions),
    };

    if (!passwordError && !confirmPasswordError) {
      try {
        const response = await fetch(url, OPTIONS);

        if (!response.ok) {
          console.error("Error:", response.status, response.statusText);
          return;
        }
        const data = await response.json();
        console.log(data);
        alert(data.message);
        navigate("/");
      } catch (error) {
        console.error("Fetch error:", error);
      }

      console.log("Form submitted successfully!");
    }
  };

  return (
    <MainContentRoot className={className} onSubmit={handleSubmit}>
      <Header>
        <Text1>
          <MainTitle>Setup New Password</MainTitle>
          <SubTitle>
            <HaveYouAlready>{`Have you already reset the password? `}</HaveYouAlready>
            <Link to="/">
              <SignIn>Sign in</SignIn>
            </Link>
          </SubTitle>
        </Text1>
      </Header>
      <Form>
        <InputFields>
          <RectangleParent>
            <PasswordInput
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <EyeIconToggler
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          </RectangleParent>
          {passwordError && <StrengthLabel>{passwordError}</StrengthLabel>}
          <StrengthIndicator>
            <StrengthContainer>
              <Strip>
                <StrengthBar passwordStrength={passwordStrength >= 1 ? 2 : 0} />
                <StrengthBar passwordStrength={passwordStrength >= 2 ? 3 : 0} />
                <StrengthBar passwordStrength={passwordStrength >= 3 ? 4 : 0} />
                <StrengthBar passwordStrength={passwordStrength >= 4 ? 5 : 0} />
              </Strip>
              <Text2>
                <StrengthLabel>{`Use 8 or more characters with a mix of letters, numbers & symbols.`}</StrengthLabel>
              </Text2>
            </StrengthContainer>
          </StrengthIndicator>
          <RectangleParent>
            <PasswordInput
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <EyeIconToggler
              showPassword={showConfirmPassword}
              handleShowPassword={toggleShowConfirmPassword}
            />
          </RectangleParent>
          {confirmPasswordError && (
            <StrengthLabel>{confirmPasswordError}</StrengthLabel>
          )}
        </InputFields>
      </Form>
      <Options>
        <RememberMe>
          <Icontext>
            <Iconset>
              <CheckboxIcon
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
            </Iconset>
            <Text4>
              <Text3>
                <HaveYouAlready>{`I Accept the `}</HaveYouAlready>
                <SignIn>Terms</SignIn>
              </Text3>
            </Text4>
          </Icontext>
        </RememberMe>
        <RectangleContainer
          disabled={!isChecked}
          onClick={handleSubmit}
          type="submit"
        >
          <FrameInner />
          <Submit>Submit</Submit>
        </RectangleContainer>
      </Options>
    </MainContentRoot>
  );
};

MainContent.propTypes = {
  className: PropTypes.string,
};

export default MainContent;
