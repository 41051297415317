import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import styled from "styled-components";
import {useState} from 'react';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  // height:100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center; 
  align-items: center; 
  height:100%;
  
  `;

const SidebarContainer = styled.div`
  //  width: ${({ isCollapsed }) => (isCollapsed ? '80px' : '300px')};
  transition: width 0.3s ease;
  background-color:blue;
 `;
const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1210);
  return (
    <LayoutContainer>
      <SidebarContainer>
        <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      </SidebarContainer>
      <ContentContainer>{children}</ContentContainer>
    </LayoutContainer>
  );
};

export default Layout;
