import { createContext } from "react";
import { useState } from "react";

export const passwordResetContext = createContext();

function PasswordResetContextProvider({ children }) {
  const [userPasswordReset, setUserPasswordReset] = useState({
    passwordResetEmail: "",
    passwordResetToken: "",
    newPassword:"",
  });
  // console.log("incontext", userPasswordReset);
  return (
    <passwordResetContext.Provider
      value={{
        userPasswordReset,
        setUserPasswordReset,
      }}
    >
      {children}
    </passwordResetContext.Provider>
  );
}

export { PasswordResetContextProvider };
