import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ThemeToggler from "../ThemeToggler/themetoggler";
import { useTheme } from "styled-components";

const GroupIcon = styled.img`
  height: 43.1px;
  width: 43.1px;
  position: relative;
  @media screen and (max-width: 375px) {
      height: 44.1px;
      width: 26.1px;
  }
  @media screen and (max-width: 390px) {
       height: 43px;
       width: 30px;
  }
  @media screen and (max-width: 430px) {
       height: 43px;
       width: 26px;
  }
  @media screen and (max-width:768px) {
       height: 43px;
       width: 33px;
  }
  @media screen and (max-width:820px) {
       height: 43px;
       width: 40px;
  }
  @media screen and (max-width:820px) {
       height: 43px;
       width: 30px;
  }
`;

const FrameChild = styled.img`
  align-self: stretch;
  height: 34px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const ContentInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.3px 0px 0px;
`;

const Content = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.7px;
 @media screen and (max-width: 375px) {
    width:164px;
  }
  @media screen and (max-width: 390px) {
    width:165px;
  }
  @media screen and (max-width: 430px) {
    width:153px;
  }
  @media screen and (max-width:768px) {
    width:153px;
  }
  @media screen and (max-width:820px) {
    width:163px;
  }
`;

// const MainChild = styled.img`
//   height: 55px;
//   width: 55px;
//   position: relative;
//   cursor: pointer;
//   @media screen and (max-width: 375px) {
//       height: 40px;
//       width: 40px;
//   }
//   ${({ theme }) =>
//     theme === "dark" &&
//     `
//     filter: invert(1);
//   `}
// `;

const MainRoot = styled.div`
  width: 930px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 450px) {
     justify-content: center;
  }
  @media screen and (max-width: 768px) {
     justify-content: space-around;
  }
  @media screen and (max-width: 820px) {
     justify-content: space-around;
  }
  @media screen and (max-width: 1024px) {
     justify-content: space-evenly;
  }
  @media screen and (max-width: 1280px) {
    justify-content: space-evenly;
     width:1050px;
  }
`;

const Main = ({ className = "",}) => {
  const theme = useTheme();
  const groupLogo = theme.images.groupLogo;
  const groupLogoName = theme.images.groupLogoName;

  return (
      <MainRoot className={className} >
        <Content>
          <GroupIcon loading="lazy" alt="" src={groupLogo} />
          <ContentInner>
            <FrameChild loading="lazy" alt="" src={groupLogoName} />
          </ContentInner>
        </Content>
        <ThemeToggler/>
        {/* <MainChild
          loading="lazy"
          alt=""
          src={theme==="light" ?"images/theme-light.svg":"images/theme-dark.svg"}
          onClick={handleThemeToggle}
          
        /> */}
      </MainRoot>
   
  );
};

Main.propTypes = {
  className: PropTypes.string,
};

export default Main;
