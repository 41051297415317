import { createContext } from "react";
import { useState, useEffect } from "react";

export const userContext = createContext();

function UserContextProvider({ children }) {
  const getDetailsifAny = () => {
    const savedUserDetails = localStorage.getItem("userDetails");

    return savedUserDetails
      ? JSON.parse(savedUserDetails)
      : {
          userName: "",
          userEmailId: "",
          isLoggedin: false,
          isUserAdmin: false,
          userId: "",
          userToken: "",
        };
  };

  const [userDetails, setUserDetails] = useState(getDetailsifAny());
  // console.log(userDetails);

  const logout = () => {
    setUserDetails({
      userName: "",
      userEmailId: "",
      isLoggedin: false,
      isUserAdmin: false,
      userId: "",
      userToken: "",
    });
    localStorage.removeItem("userDetails");
    localStorage.removeItem("fileName");
    localStorage.removeItem("userId")
  };

  const [userImg, setUserImg] = useState("/images/membericon.png");

  return (
    <userContext.Provider value={{ userDetails, setUserDetails, logout, userImg, setUserImg }}>
      {children}
    </userContext.Provider>
  );
}
export { UserContextProvider };
