import { useState } from "react";

const usePasswordVisibility = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return {
    showPassword,
    handleShowPassword,
  };
};

export default usePasswordVisibility;
