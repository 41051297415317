import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoute = () => {
  const storedUserDetails = localStorage.getItem("userDetails");
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;

  const user = userDetails?.isLoggedin;

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
