import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Main from "../components/Forgotpassword/content-images";
import GroupComponent from "../components/Forgotpassword/forgotpassword-form";
import { lightModeTheme, darkModeTheme } from "../pages/theme";


const FrameParent = styled.section`
  width: 1366px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 142px;
  max-width: 100%;
  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
    gap: 71px;
  }
  @media screen and (max-width: 825px) {
    gap: 35px;
  }
  @media screen and (max-width: 450px) {
    gap: 18px;
  }
`;

const ForgotPasswordInner = styled.main`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
`;

const ForgotPasswordRoot = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  // background-color: ${({ theme }) => theme.background};
  // color: ${({ theme }) => theme.color};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 50px 142px 124px 91px;
  box-sizing: border-box;
  gap: 53px;
  line-height: normal;
  letter-spacing: normal;
  @media screen and (max-width: 1200px) {
    padding-left: 71px;
    padding-right: 71px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 825px) {
    gap: 26px;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
  }
    @media screen and (max-width: 853px) {
    gap: 0px;
  }
`;

const ForgotPassword = () => {
  // const [theme, setTheme] = useState("light");

  // const handleThemeToggle = () => {
  //   setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  // };

  // const currentTheme = theme === "light" ? lightModeTheme : darkModeTheme;

  return (
  
      <ForgotPasswordRoot>
        <Main />
        <ForgotPasswordInner>
          <FrameParent>
            <GroupComponent />
            
          </FrameParent>
        </ForgotPasswordInner>
      </ForgotPasswordRoot>
    
  );
};

export default ForgotPassword;
