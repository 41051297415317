import styled from "styled-components";
import InputTrio1 from "./email-input";
import InputTrio from "./password-input";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

const FrameChild = styled.div`
  width: 505px;
  height: 757px;
  position: relative;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #fff;
  border: 0.5px solid #878787;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const Welcome = styled.h3`
  margin: 0;
  position: relative;
  font-size: var(--font-size-6xl);
  font-weight: 300;
  font-family: var(--font-geist);
  line-height: 31px;
  color: ${({ theme }) => theme.font.primary};
  text-align: left;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
`;
const SignUp = styled.h2`
  margin: 0;
  position: relative;
  font-size: var(--font-size-12xl);
  font-weight: 500;
  line-height: 38.44px;
  font-family: var(--font-geist);
  color: ${({ theme }) => theme.font.primary};
  white-space: nowrap;
  text-align: left;
  display: inline-block;
  min-width: 110px;
  z-index: 1;
  @media screen and (max-width: 825px) {
    font-size: 25px;
  }
  @media screen and (max-width: 450px) {
    font-size: 19px;
  }
`;
const LoremIpsumIs = styled.div`
  align-self: stretch;
  position: relative;
   font-family: var(--font-geist);
  font-weight:400;
  font-size: var(--font-size-base);
  line-height:19.84px
  color: ${({ theme }) => theme.font.primary};
  text-align: left;
  z-index: 1;
`;
const Description = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 13px;
  box-sizing: border-box;
  gap: 10px;
`;
const FrameItem = styled.div`
  height: 57px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #000;
  display: none;
  max-width: 100%;
`;
const Register = styled.div`
  position: relative;
  font-family: Geist;
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 19.84px;
  color: #ffffff;
  text-align: left;
  display: inline-block;
  min-width: 65px;
  z-index: 1;
`;
const RectangleGroup = styled.button`
  cursor: pointer;
  border: none;
  padding: 17px 20px 19px;
  background-color: ${({ theme }) => theme.backgroundColors.buttonColor};
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 1;
  &:hover {
    background-color: #333;
  }
`;
const ConfirmPasswordInputParent = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 3px;
  box-sizing: border-box;
  gap: 31px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 15px;
  }
`;
const AlreadyHaveAn = styled.span`
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 300;
  line-height: 19.84px;
  color: ${({ theme }) => theme.font.smallText};
`;
const Login = styled.span`
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 600;
  line-height: 19.84px;
  color: ${({ theme }) => theme.font.primary};
  white-space: pre-wrap;
`;
const AlreadyHaveAnContainer = styled.div`
  position: relative;
  font-size: 16px;
  text-align: left;
  z-index: 1;
`;
const AlreadyHaveAnAccountLogiWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 69px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`;
const RectangleParentRoot = styled.form`
  margin: 0;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  border: ${({ theme }) => theme.border.primary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 13px 35px 27px;
  gap: 17px;
  margin-top: 50px;
  width: 100%;

  @media screen and (max-width: 450px) {
    padding: 10px 15px;
  }
`;

const RectangleParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-width:505px;
  @media screen and (max-width: 825px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 450px) {
    padding: 0 10px;
  }
`;

const FrameChild2 = styled.img`
  height: 43px;
  width: 210px;
`;
const GroupComponent = ({ className = "" }) => {
  const theme = useTheme();
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const groupLogoName = theme.images.fullLogo;
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const handleSignup = async (event) => {
    event.preventDefault();
    console.log("User data:", user);
    const url = "http://129.146.99.175:4000/api/users/register";

    const OPTIONS = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    };

    try {
      const response = await fetch(url, OPTIONS);

      if (!response.ok) {
        console.error("Error:", response.status, response.statusText);
        return;
      }

      const data = await response.json();
      navigate("/");
      console.log("Response data:", data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    console.log("Event value:", name, value); // Log the event value
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  return (
    <RectangleParent>
      <FrameChild2 loading="lazy" alt="" src={groupLogoName} />

      <RectangleParentRoot className={className} onSubmit={handleSignup}>
        <FrameChild />
        <Welcome>Welcome!</Welcome>
        <Description>
          <SignUp>{`Sign up  `}</SignUp>
          <LoremIpsumIs>{`Lorem Ipsum is simply `}</LoremIpsumIs>
        </Description>
        <InputTrio1
          email="Email"
          enterYourEmailPlaceholder="Enter your email"
          name="email"
          value={user.email}
          onChange={handleOnChange}
        />
        <InputTrio1
          email="User name"
          enterYourEmailPlaceholder="Enter your user name"
          name="name"
          value={user.name}
          onChange={handleOnChange}
        />
        <InputTrio
          password="Password"
          frame9Placeholder="Enter your Password"
          name="password"
          value={user.password}
          onChange={handleOnChange}
          show={show}
          setShow={setShow}
        />
        <InputTrio
          password="Confirm Password"
          frame9Placeholder="Confirm your Password"
          name="confirmPassword"
          value={user.confirmPassword}
          onChange={handleOnChange}
          show={show1}
          setShow={setShow1}
        />
        <ConfirmPasswordInputParent>
          <RectangleGroup type="submit">
            <FrameItem />
            <Register>Register</Register>
          </RectangleGroup>
        </ConfirmPasswordInputParent>

        {/* <InputTrio password="Password" frame9Placeholder="Enter your Password" show = {show}
          setShow ={setShow} /> */}
        {/* <ConfirmPasswordInputParent>
        <InputTrio
          password="Confirm Password"
          frame9Placeholder="Confirm your Password"
          propWidth="unset"
          propPadding="unsetemail"
          propAlignSelf="stretch"
          show = {show1}
          setShow ={setShow1}
          
        />
        <RectangleGroup>
          <FrameItem />
          <Register>Register</Register>
        </RectangleGroup>
      </ConfirmPasswordInputParent> */}

        <AlreadyHaveAnAccountLogiWrapper>
          <AlreadyHaveAnContainer>
            <AlreadyHaveAn>Already have an Account?</AlreadyHaveAn>
            <Link to="/">
              <Login>Login</Login>
            </Link>
          </AlreadyHaveAnContainer>
        </AlreadyHaveAnAccountLogiWrapper>
      </RectangleParentRoot>
    </RectangleParent>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent;
