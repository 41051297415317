import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ThemeToggler from "../ThemeToggler/themetoggler";
import { useTheme } from "styled-components";

const GroupIcon = styled.img`
  height: 43.1px;
  width: 43.1px;
  position: relative;
`;

const FrameChild = styled.img`
  align-self: stretch;
  height: 34px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const ContentInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.3px 0px 0px;
`;

const Content = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.7px;
`;

const MainChild = styled.img`
  height: 55px;
  width: 55px;
  position: relative;
  cursor: pointer;
  ${({ theme }) =>
    theme === "dark" &&
    `
    filter: invert(1);
  `}
`;

const MainRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
`;

const TogglerWrapper = styled.div`
  
`;

const Main = ({ className = ""}) => {
  const theme = useTheme();
  const groupLogo = theme.images.groupLogo;
  const groupLogoName = theme.images.groupLogoName;
  
  return (
      <MainRoot className={className} theme={theme}>
        {/* <Content>
          <GroupIcon loading="lazy" alt="" src={groupLogo} />
          <ContentInner>
            <FrameChild loading="lazy" alt="" src={groupLogoName} />
          </ContentInner>
        </Content> */}
        <ThemeToggler />
        {/* <MainChild
          loading="lazy"
          alt=""
          src={theme==="light" ?"images/theme-light.svg":"images/theme-dark.svg"}
          onClick={handleThemeToggle}
          
        /> */}
      </MainRoot>
   
  );
};

Main.propTypes = {
  className: PropTypes.string,
};

export default Main;
