import { createContext } from "react";
import {useState} from "react";


export const themeContext = createContext();

function ThemeContextProvider({ children }) {
    const [theme, setTheme] = useState("light");
    return (
      <themeContext.Provider value={{theme, setTheme}}>{children}</themeContext.Provider>
    );
  }
  export { ThemeContextProvider };