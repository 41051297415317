// ThemedApp.js
import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";
import { themeContext } from "./components/Contexts/themeContext";
import GlobalStyles from "./global";
import { lightTheme, darkTheme } from "./pages/theme";
import App from "./App";
import { UserContextProvider } from "./components/Contexts/userContext";
import { PasswordResetContextProvider } from "./components/Contexts/passwordResetContext";

const ThemedApp = () => {
  const { theme } = useContext(themeContext);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
       <UserContextProvider>
        <PasswordResetContextProvider>
      <GlobalStyles />
      <App />
      </PasswordResetContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default ThemedApp;
