/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled, { useTheme } from "styled-components";
import Content from "../components/Newchat/Content";
import { userContext } from "../components/Contexts/userContext";
const Text3 = styled.div`
  flex: 1;
  position: relative;
  line-height: 20px;
  color: ${({ theme }) => theme.font.primary};
`;
const Text4 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundColors.newchatText};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;
`;
const ChatContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  justify-content: flex-center;
  gap: 16px;
  padding-top: 205px;
`;
const ChatStructure = styled.div`
  width: 57.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 20px;
  }
    @media screen and (max-width: 769px){
    flex-direction:column;
    }
`;
const ChatPanel = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  max-width: 100%;
`;
const VectorIcon1 = styled.img`
  height: 24.26px;
  width: 22.37px;l
  position: relative;
`;
const Iconset = styled.div`
  height: 16.3px;
  width: 14.4px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Button = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;
const Group = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
const UploadFileInput = styled.input`
  display: none;
  cursor: pointer;
`;

const Text5 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Icontext = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
  @media screen and (max-width: 750px) {
    padding-right: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-right: 0px;
    box-sizing: border-box;
  }
`;
const PaperplanerightIcon = styled.img`
  height: 20px;
  width: 20px;
  position: relative;
`;
const Iconset1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Content1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px 0px 0px;
  box-sizing: border-box;
  gap: 8px 6px;
  min-height: 24px;
  max-width: 100%;
`;

const Input = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.backgroundColors.newchatText};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15.9px 20px 15.8px;
  box-sizing: border-box;
  max-width: 100%;
`;

const InputBox = styled.footer`
  align-self: stretch;
  height: 116px;
  backdrop-filter: blur(100px);
  background: ${({ theme }) => theme.backgroundColors.body};
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 20px;
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Corrected value */
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 15px;
  color: rgba(28, 28, 28, 0.2);
  font-family: Geist;

  @media screen and (max-width: 1469px) {
  }
`;

const InnerContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 274px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    gap: 137px;
  }
  @media screen and (max-width: 750px) {
    gap: 68px;
  }
  @media screen and (max-width: 450px) {
    gap: 34px;
  }
`;
const InnerContentWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-center;
  justify-content: flex-center;
  // text-align: left;
  font-family: var(--FontfamilyInter);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  color: #1c1c1c;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.backgroundColors.newchatText};
  width: 750px;
  border-radius: 8px;
  padding: 4px 8px;
  box-sizing: border-box;
  font-family: Geist;
  font-size: 14px;
  color: ${({ theme }) => theme.font.primary};
  max-width: 100%;
`;

const RightPanel = styled.main`
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  // background-color: yellow;
`;

const ChatMessage = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.chatmessage};
  color: ${({ theme }) => theme.font.primary};
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  display: flex;
  justify-content: ${(props) => (props.isuser ? "flex-end" : "flex-start")};
  flex-direction: ${(props) => (props.isuser ? "row-reverse" : "row")};
`;

const ChatImage = styled.img`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  object-fit: cover;
  align-self: ${(props) => (props.isuser ? "flex-end" : "flex-start")};
`;

const ChatBody = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  flex-direction: column;
  height: calc(90vh - 200px); /* Adjust this based on your layout */

  /* Hide the scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For other browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const NewchatRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 64px 0px 0px;
  box-sizing: border-box;
  gap: 13.9px;
  line-height: normal;
  letter-spacing: normal;
`;
const FileTypeIcon = styled.img`
  width: 28px;
  height: 37.44px;
  margin-right: 20px;
`;
const FileIconContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 30px;
  font-family: Geist;
  font-size: 20px;
  font-weight: 500;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 28px;
  padding: 30px;
  font-family: Geist;
  font-size: 18px;
`;

const ImageContainer = styled.img`
  display: flex;
  align-items: center;
  width: 150px;
  height: 100px;
`;

const Newchat = () => {
  const themes = useTheme();
  const [loading, setLoading] = useState(false);

  const [fileData, setFileData] = useState({
    userId: localStorage.getItem("userId") || "",
    fileName: localStorage.getItem("fileName") || "",
  });

  const { userDetails, userImg } = useContext(userContext);
  console.log(userDetails);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [content, setcontent] = useState(true);
  const uploadFile = themes.images.uploadFile;
  const sendButton = themes.images.sendIcon;
  const loadingGif = themes.images.loading;
  const chatBodyRef = useRef(null);
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    setcontent(false);
    if (message.trim() === "") return;

    const newMessage = { text: message, isuser: true };
    setMessages([...messages, newMessage]);

    setMessage("");
    if (fileData.userId === "" && fileData.fileName === "") {
      fileData.fileName = localStorage.getItem("fileName");
      fileData.userId = localStorage.getItem("userId");
      if (fileData.fileName === "" && fileData.userId === "") {
        const botMessage = {
          text: `Please upload file to run the query`,
          isuser: false,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }
    } else if (message !== "") {
      setLoading(true);
      const url = "http://129.146.99.175:4000/api/minio/askKashikaResponse";
      const OPTIONS = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: message,
          uploaded_file_names: fileData.fileName,
          user_id: fileData.userId,
        }),
      };
      try {
        const response = await fetch(url, OPTIONS);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const botMessage = { text: `${data.text_response}`, isuser: false };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading state to false
      }
    }
    setMessage("");
  };

  const handleFileUpload = async (event) => {
    setcontent(false);
    const file = event.target.files[0];
    if (file) {
      const url = "http://129.146.99.175:4000/api/minio/upload";

      const formData = new FormData();
      formData.append("files", file);
      formData.append("token", userDetails?.userToken);

      const OPTIONS = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(url, OPTIONS);
        if (response.ok) {
          const data = await response.json();
          const reqDetails = data.files[0];
          const newMessage = {
            text: `${reqDetails.fileName}`,
            isuser: true,
            fileType: data.type,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          // Update the state with the new values
          setFileData((prevState) => ({
            ...prevState,
            userId: reqDetails.username,
            fileName: reqDetails.fileName,
          }));
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const getIconForFileType = (fileType) => {
    switch (fileType) {
      case ".pdf":
        return "/images/pdflogo.svg";
      case ".csv":
        return "/images/csvfile.png";
      case ".txt":
        return "/images/textfile.svg";
      default:
        return "path/to/icons/default-icon.svg";
    }
  };

  useEffect(() => {
    localStorage.setItem("fileName", fileData.fileName);
    localStorage.setItem("userId", fileData.userId);
  }, [fileData]);

  useEffect(() => {
    if (fileData.userId !== "" && fileData.fileName !== "") {
      const newMessage = {
        text: fileData.fileName,
        isuser: true,
        fileType: `.${fileData.fileName.split(".")[1]}`,
      };
      setMessages([newMessage]);
    }
  }, []);

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      handleEnter(e);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleEnter]);

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  // useEffect to scroll to the bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <RightPanel>
        <InnerContentWrapper>
          <InnerContent>
            <ChatPanel>
              <ChatStructure>
                {!content ? (
                  <ChatBody ref={chatBodyRef}>
                    {messages.map((msg, index) => (
                      <React.Fragment key={index}>
                        <ChatMessage key={`msg-${index}`} isuser={msg.isuser}>
                          {msg.fileType ? (
                            <div style={{ display: "flex" }}>
                              <FileIconContainer>
                                <FileTypeIcon
                                  src={getIconForFileType(msg.fileType)}
                                  alt={`fileicon`}
                                />
                                {msg.text}
                              </FileIconContainer>
                            </div>
                          ) : (
                            <div>
                              <TextContainer>{msg.text}</TextContainer>
                            </div>
                          )}
                        </ChatMessage>
                        <ChatImage
                          key={`img-${index}`}
                          isuser={msg.isuser}
                          src={
                            msg.isuser ? userImg : "images/chaticonkashika.svg"
                          }
                          alt="chaticons"
                        />
                      </React.Fragment>
                    ))}
                    {loading && (
                      <>
                        <ChatMessage isuser={false}>
                          <ImageContainer src={loadingGif} alt="loading" />
                        </ChatMessage>
                        <ChatImage
                          isuser={false}
                          src="images/chaticonkashika.svg"
                          alt="chaticons"
                        />
                      </>
                    )}
                  </ChatBody>
                ) : (
                  <>
                    <ChatContent>
                      <Content
                        chats="/images/chat1.png"
                        chatListItemName="Examples"
                      />

                      <Text4>
                        <Text3>
                          Explain quantum computing in simple terms?
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          Got any creative ideas for a 10 year old's birthday?
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          How do I make an HTTP requesting Javascript?
                        </Text3>
                      </Text4>
                    </ChatContent>

                    <ChatContent>
                      <Content
                        chats="/images/chat2.png"
                        chatListItemName="Capabilities"
                      />
                      <Text4>
                        <Text3>
                          Remembers what user said earlier in the conversation.
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          Allows user to provide follow-up corrections.
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          Trained to decline inappropriate requests.
                        </Text3>
                      </Text4>
                    </ChatContent>
                    <ChatContent>
                      <Content
                        chats="/images/chat3.png"
                        chatListItemName="Limitations"
                      />
                      <Text4>
                        <Text3>
                          May occasionally generate incorrect information.
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          May occasionally produce harmful instructions or
                          biased content.
                        </Text3>
                      </Text4>

                      <Text4>
                        <Text3>
                          Limited knowledge of world and events after 2021.
                        </Text3>
                      </Text4>
                    </ChatContent>
                  </>
                )}
              </ChatStructure>
            </ChatPanel>

            <InputBox>
              <Input>
                <Content1>
                  <Icontext>
                    <Group>
                      <UploadFileInput
                        type="file"
                        id="fileInput"
                        accept=".pdf, .txt, .csv"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                      />
                      <Button onClick={handleClick}>
                        <Iconset>
                          <VectorIcon1 alt="" src={uploadFile} />
                        </Iconset>
                      </Button>
                    </Group>
                    <Text5>
                      <InputField
                        type="text"
                        placeholder="Type message"
                        value={message}
                        onChange={handleInputChange}
                      />
                    </Text5>
                  </Icontext>
                  <Iconset1 onClick={handleSendMessage}>
                    <PaperplanerightIcon alt="" src={sendButton} />
                  </Iconset1>
                </Content1>
              </Input>
            </InputBox>
          </InnerContent>
        </InnerContentWrapper>
      </RightPanel>
    </>
  );
};

export default Newchat;
