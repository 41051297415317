import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import ThemeToggler from "../ThemeToggler/themetoggler";

const GroupIcon = styled.img`
  height: 43.1px;
  width: 43.1px;
  position: relative;
  @media screen and (max-width: 360px) {
    height: 45.1px;
    width: 18.1px;
  }
  @media screen and (max-width: 344px) {
    height: 47.1px!important;
    width: 20.1px!important;
  }
  @media screen and (max-width: 425px) {
    height: 47.1px;
    width: 28.1px;
  }
  @media screen and (max-width: 820px) {
    height: 47.1px!important;
    width: 28.1px!important;
  }
   @media screen and (max-width: 853px) {
    
  }
  @media screen and (max-width: 912px) {
    height: 47.1px!important;
    width: 28.1px!important;
  }
  @media screen and (max-width: 425px) {
    height: 44.1px;
    width: 23.1px;
  }
  @media screen and (max-width: 768px) {
    height: 47px;
    width: 28px!important;
  }
  // @media screen and (max-width: 375px) {
  //   height:44.1px!important;
  //   width:18.1px!importannt;
  // }
  @media screen and (max-width: 430px) {
    height: 47px;
    width: 22px;
  }
  @media screen and (max-width: 1024px) {
    height: 47px;
    width: 30px!important;
  }
 
`;

const FrameChild = styled.img`
  align-self: stretch;
  height: 34px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const ContentInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.3px 0px 0px;
`;

const Content = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.7px;
  @media screen and (max-width: 344px) {
    width: 110px!important;
  }
  @media screen and (max-width: 360px) {
    width: 150px;
  }
  @media screen and (max-width: 425px) {
    width: 150px;
  }
  @media screen and (max-width: 820px) {
    width: 138px!important;
  }
  // @media screen and (max-width: 853px) {
  //   width: 134px!important;
  // }
  @media screen and (max-width: 912px) {
    width: 160px;
  }
  @media screen and (max-width: 425px) {
    width: 110px;
  }
  // @media screen and (max-width: 375px) {
  //   width: 95px!important;
  // } 
  @media screen and (max-width: 430px) {
    width: 100px;
  }
  @media screen and (max-width: 1024px) {
    width: 150px;
  }
  @media screen and (max-width: 344px) {
    width: 110px!important;
  }  
`;



const MainRoot = styled.div`
  width: 930px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  padding-bottom:30px;
  // background-color: ${({ theme }) => theme.background};
  // color: ${({ theme }) => theme.color};
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 820px) {
    justify-content: space-around;
  }
  @media screen and (max-width: 1024px) {
    justify-content: space-evenly;
  }
  @media screen and (max-width: 912px) {
    padding-bottom:0px;
  }
  @media screen and (max-width: 540px) {
    padding-bottom:20px;
  }
 @media screen and (max-width: 853px) {
    padding-bottom:20px;
  } 
  @media screen and (max-width: 1280px) {
    justify-content: space-evenly;
    width: 1030px;
  }
`;

const Main = ({ className = "",}) => {
  const theme = useTheme();
  const groupLogo = theme.images.groupLogo;
  const groupLogoName = theme.images.groupLogoName;

  return (
      <MainRoot className={className} >
        <Content>
          <GroupIcon loading="lazy" alt="" src={groupLogo} />
          <ContentInner>
            <FrameChild loading="lazy" alt="" src={groupLogoName} />
          </ContentInner>
        </Content>
        <ThemeToggler/>
      </MainRoot>
   
  );
};

Main.propTypes = {
  className: PropTypes.string,
};

export default Main;
