import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    background: ${({ theme }) => theme.backgroundColors.body};
    //  color: ${({ theme }) => theme.text};
    },
      a {
    text-decoration: none;
  }
:root {
 
/* fonts */
--font-geist: Geist;
--FontfamilyInter: Inter;
 
/* font sizes */
--font-size-base: 16px;
--font-size-xs: 12px;
--font-size-sm: 14px;
--font-size-12xl: 31px;
--font-size-6xl: 25px;
--font-size-lgi: 19px;
--font-size-xl: 20px;
 
/* Colors */
--color-white: #fff;
--color-gray-100: #878787;
--color-gray-200: #7d7d7d;
--color-gray-300: #282828;
--color-black: #000;
--color-darkslategray: #333;
--color-dimgray: #4d4d4d;
--color-darkgray: #ababab;
--color-error: #FF0000; /* Add this line for error color */
  
 
/* Gaps */
--gap-123xl: 142px;
--gap-16xl: 35px;
--gap-lg: 18px;
--gap-52xl: 71px;
--gap-17xl: 36px;
--gap-4xl: 23px;
--gap-xl: 20px;
--gap-3xs: 10px;
--gap-smi: 13px;
--gap-2xs-7: 10.7px;
 
/* Paddings */
--padding-52xl: 71px;
--padding-16xl: 35px;
--padding-78xl: 97px;
--padding-44xl: 63px;
--padding-17xl: 36px;
--padding-25xl: 44px;
--padding-4xl: 23px;
--padding-10xl: 29px;
--padding-58xl: 77px;
--padding-xl: 20px;
--padding-mid: 17px;
--padding-9xs: 4px;
--padding-12xs: 1px;
--padding-lg: 18px;
--padding-9xl: 28px;
--padding-8xl: 27px;
--padding-11xs: 2px;
--padding-base: 16px;
--padding-8xs-3: 4.3px;
 
/* Border radiuses */
--br-3xs: 10px;
--br-7xs: 6px;
 
}
/* Define your CSS variables */
  :root {
    /* Fonts */
    --font-geist: 'Geist', sans-serif;
    --regular: 'Inter', sans-serif;

    /* Font sizes */
    --font-size-sm: 14px;
    --font-size-base: 16px;
    --regular-size: 18px;
    --font-size-5xl: 24px;
    --font-size-lgi: 19px;

    /* Colors */
    --white-100: #fff;
    --black-100: #1c1c1c;
    --black-10: rgba(28, 28, 28, 0.1);
    --white-80: rgba(255, 255, 255, 0.8);
    --black-40: rgba(28, 28, 28, 0.4);
    --color-blue: #0000ff;
    --color-black: #000;

    /* Spacing */
    --boundvariablesdata: 28px;
    --boundvariablesdata1: 24px;
    --boundvariablesdata2: 8px;
    --boundvariablesdata3: 8px;
    --boundvariablesdata6: 16px;
    --boundvariablesdata7: 80px;

    /* Gaps */
    --gap-lg: 18px;
    --gap-5xs: 8px;

    /* Paddings */
    --padding-72xl: 91px;
    --padding-2xl: 21px;
    --padding-xl: 20px;
    --padding-9xl: 28px;
    --padding-mid: 17px;
    --padding-160xl: 179px;
    --padding-sm: 14px;
    --padding-5xl: 24px;
    --padding-xs: 12px;
    --padding-3xl: 22px;
    --padding-3xs: 10px;

    /* Border radiuses */
    --br-5xs: 8px;
    --br-7xs: 6px;
    --br-base: 16px;
  }


`;
