import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ThemeToggler from "../ThemeToggler/themetoggler";
import { useTheme } from "styled-components";

const GroupIcon = styled.img`
  height: 43.1px;
  width: 43.1px;
  position: relative;
`;

const SidebarItemChild = styled.img`
  align-self: stretch;
  height: 34px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const SidebarItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.3px 0px 0px;
`;

const GroupParent = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.7px;
`;

const FrameChild = styled.img`
  width: 55px;
  height: 55px;
  position: relative;
  cursor: pointer;

`;

const LeftSidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 0px 35.1px;
`;

const LeftSidebar = styled.div`
  width: 923px;
  display: flex;
  flex-direction: row;
  // align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

const Main1 = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 100%;
`;


const SetNewPassword = ({ className = "",}) => {

  const theme = useTheme();
  const groupLogo = theme.images.groupLogo;
  const groupLogoName = theme.images.groupLogoName;
  return (
      <Main1 className={className}>
        <LeftSidebar>
          <GroupParent>
          <GroupIcon loading="lazy" alt="" src={groupLogo} />
            <SidebarItem>
              <SidebarItemChild
                loading="lazy"
                alt=""
                src={groupLogoName}
              />
            </SidebarItem>
          </GroupParent>
          <LeftSidebarInner>
          <ThemeToggler/>
          </LeftSidebarInner>
        </LeftSidebar>
      </Main1>
  );
};
SetNewPassword.propTypes = {
    className: PropTypes.string,
  };
  
export default SetNewPassword;