import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { userContext } from "../Contexts/userContext";
import { useContext } from "react";
import { passwordResetContext } from "../Contexts/passwordResetContext";
import { useNavigate } from "react-router-dom";

const FrameItem = styled.div`
  height: 57px;
  width: 423px;
  position: relative;
  border-radius: 10px;
  background-color: #000;
  display: none;
  max-width: 100%;
`;
const Submit = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 19.84px;
  color: #ffffff;
  text-align: left;
  display: inline-block;
  min-width: 65px;
  z-index: 1;
`;
const RectangleGroup = styled.button`
  cursor: pointer;
  border: none;
  padding: 17px 20px 19px;
  background-color: ${({ theme }) => theme.backgroundColors.buttonColor};
  border-radius: 6px;
  width: 100%;
  max-width: 423px;
  box-sizing: border-box;
  text-align: center;
  &:hover {
    background-color: #333;
  }
  @media screen and (max-width: 390px) {
    width: 250px;
  }
  @media screen and (max-width: 768px) {
    padding: 17px 10px 19px;
  }
`;
const SubmitButtonParent = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 3px;
  box-sizing: border-box;
  gap: 31px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 15px;
  }
  @media screen and (max-width: 414px) {
    width: 240px;
  }
`;
const RectangleParentRoot = styled.form`
  margin: 0;
  width: 50%;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border: 0.6px solid var(--color-gray-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 57px 35px 73px;
  gap: 17px;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    width: 75%;
    padding: 13px 20px 27px;
  }
  @media screen and (max-width: 375px) {
    width: 100% !important;
    padding: 13px 20px 27px !important;
  }
  @media screen and (max-width: 414px) {
    width: 100% !important;
    padding: 13px 20px 27px !important;
  }
  @media screen and (max-width: 430px) {
    width: 100% !important;
    padding: 13px 20px 27px !important;
  }
  @media screen and (max-width: 540px) {
    width: 100% !important;
    padding: 13px 20px 27px !important;
  }
  @media screen and (max-width: 820px) {
    width: 67%;
    padding: 13px 20px 27px;
  }
  @media screen and (max-width: 1024px) {
    width: 67%;
    padding: 13px 20px 27px;
  }
  @media screen and (max-width: 912px) {
    width: 78%;
    padding: 13px 20px 27px;
  }
  @media screen and (max-width: 1280px) {
    width: 68%;
    padding: 13px 20px 27px;
  }
`;

const DidntGetTheContainer = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm)
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  z-index: 1;
`;
const DidnotGetTheCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 69px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`;
const DidntGetThe = styled.span`
 font-family: var(--font-geist);
font-size: var(--font-size-sm);
font-weight: 400;
line-height: 20px;
  color: ${({ theme }) => theme.font.minorText4};
`;
//*********************** */
const TextFrame = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--padding-2xl) var(--padding-3xs) var(--padding-xl);
  // font-size: var(--font-size-sm);
`;
const VerificationCode = styled.h2`
  margin: 0;
  align-self: center;
  position: relative;
  font-family: var(--font-geist);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.font.minorText3};
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
    line-height: 26px;
  }
`;
const CodeSubtitle = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.font.minorText4};
  align-self: center;
`;
const Text1 = styled.div`
  width: 243px;
  border-radius: var(--boundvariablesdata2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--boundvariablesdata3);
`;
const TextWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--padding-5xl) var(--padding-3xs) var(--padding-xl);
  text-align: center;
  font-size: var(--font-size-5xl);
`;
const Xyzkashikaai = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 28px;
  white-space: nowrap;
  color: ${({ theme }) => theme.font.minorText3};
`;
const Text2 = styled.div`
  border-radius: var(--boundvariablesdata2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TextContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--padding-xl) var(--padding-3xs);
`;
const TypeYour = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.font.minorText3};
  @media screen and (max-width: 430px) {
    font-size: 12px;
  }
`;
const Input = styled.input`
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: var(--boundvariablesdata6);
  font-size: 16px;
  text-align: center;
  align-self: stretch;
  flex: 1;

  // &::placeholder {
  //   font-weight: bold;
  //     color: ${({ theme }) => theme.font.minorText3};
  // }

  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};

  color: ${({ theme }) => theme.font.minorText3};

  // border: 1px solid ${({ theme }) => theme.border.borderCodeNormal};

  // &:hover {
  //   ${({ theme }) => theme.border.borderCodeActive};
  // }
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 540px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 320px) {
    width: 23px;
    height: 23px;
  }
  @media screen and (max-width: 430px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 853px) {
    width: 30px;
    height: 30px;
  }
`;
const Group = styled.div`
  align-self: stretch;
  width: 280px;
  border-radius: var(--boundvariablesdata2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--boundvariablesdata3);
  @media screen and (max-width: 412px) {
    width: 208px;
  }
  @media screen and (max-width: 320px) {
    width: 242px;
  }
  @media screen and (max-width: 430px) {
    width: 220px;
  }
`;
const GroupWrapper = styled.div`
  align-self: stretch;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-xl) var(--padding-sm) var(--padding-2xl);
  box-sizing: border-box;
  text-align: center;
  font-family: var(--font-geist);
  @media screen and (max-width: 540px) {
    margin-right: 5px;
  }
  @media screen and (max-width: 540px) {
    margin-right: 30px;
  }
`;

const Resend = styled.span`
  align-self: center;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.font.minorText5};
`;
const DevicemobileWrapper = styled.div`
  width: 423px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 0px 0px 1px;
  box-sizing: border-box;
  max-width: 100%;
`;
const DevicemobileIcon = styled.img`
  height: var(--boundvariablesdata7);
  width: var(--boundvariablesdata7);
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const GroupComponent = ({ className = "" }) => {
  const theme = useTheme();
  const userDetails = useContext(userContext);
  const navigate = useNavigate();
  const { userPasswordReset } = useContext(passwordResetContext);
  console.log("password reste token in otp page", userPasswordReset);
  console.log("email sent to ", userPasswordReset?.passwordResetEmail);
  const deviceMobile = theme.images.deviceMobile;

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);

  const handleOtpChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      // Update the corresponding index in otpValues array
      let updatedOtpValues = [...otpValues];
      updatedOtpValues[index] = value;
      setOtpValues(updatedOtpValues);

      if (value === "" && index > 0) {
        document.getElementById(`otpInput${index - 1}`).focus();
      } else if (index < 3 && value !== "") {
        document.getElementById(`otpInput${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Construct the OTP from otpValues array
    const otp = otpValues.join("");
    // Log the OTP entered by the user
    console.log("User entered OTP:", otpValues);

    const requestBody = {
      emailToken: userPasswordReset?.passwordResetToken,
      otp: otp,
    };

    const url = "http://129.146.99.175:4000/api/users/verifyUserOTP";

    const OPTIONS = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    console.log("Request body in otp page:", requestBody);
    try {
      const response = await fetch(url, OPTIONS);

      if (!response.ok) {
        console.error("Error:", response.status, response.statusText);
        return;
      }

      const data = await response.json();

      navigate("/setnewpassword");
      console.log("Response data:", data);
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <RectangleParentRoot className={className} onSubmit={handleSubmit}>
      <DevicemobileWrapper>
        <DevicemobileIcon loading="lazy" alt="" src={deviceMobile} />
      </DevicemobileWrapper>

      <TextWrapper>
        <Text1>
          <VerificationCode> Verification Code</VerificationCode>
          <CodeSubtitle>Enter the verification code we sent to</CodeSubtitle>
        </Text1>
      </TextWrapper>
      <TextContainer>
        <Text2>
          <Xyzkashikaai>{userPasswordReset?.passwordResetEmail}</Xyzkashikaai>
        </Text2>
      </TextContainer>
      <TextFrame>
        <Text2>
          <TypeYour>Type your 4 digit security code</TypeYour>
        </Text2>
      </TextFrame>
      <GroupWrapper>
        <Group>
          <InputContainer>
            {[0, 1, 2, 3].map((index) => (
              <Input
                key={index}
                type="text"
                maxLength="1"
                value={otpValues[index]}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                id={`otpInput${index}`}
              />
            ))}
          </InputContainer>
        </Group>
      </GroupWrapper>

      <SubmitButtonParent>
        <RectangleGroup type="submit">
          <FrameItem />

          <Submit>Submit</Submit>
        </RectangleGroup>
      </SubmitButtonParent>
      <DidnotGetTheCodeWrapper>
        <DidntGetTheContainer>
          <DidntGetThe>Didn’t get the code ? </DidntGetThe>
          <Link to="/forgotpassword">
            <Resend> Resend</Resend>
          </Link>
        </DidntGetTheContainer>
      </DidnotGetTheCodeWrapper>
    </RectangleParentRoot>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent;
