import styled from "styled-components";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { userContext } from "../components/Contexts/userContext";
import React, { useState } from "react";
import { useTheme } from "styled-components";
const MyAccount = styled.a`
  text-decoration: none;
  position: relative;
  font-family: var(--font-geist);
  font-size: 20px;
  font-weight: 600;
  line-height: var(--font-size-xl);
  color: ${({ theme }) => theme.font.primary};
  display: inline-block;
  min-width: 116px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
    line-height: 16px;
  }
`;
const MyAccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 9px;
`;
const ByewindIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 80px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const Iconset = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Profile = styled.a`
  text-decoration: none;
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.minorText3};
  display: inline-block;
  min-width: 43px;
`;
const Text1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Icontext = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 103px 0px 0px;
  gap: 8px;
`;
const Profile1 = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.backgroundColors.popup};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;
const PaletteIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
`;
const Support = styled.a`
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  min-width: 53px;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 400;
`;
const Icontext1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 93px 0px 0px;
  gap: 8px;
`;
const Suppport = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;
const ModelSelection = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 400;
  display: inline-block;
  min-width: 106px;
`;
const Icontext2 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 40px 0px 0px;
  gap: 8px;
`;
const ProfileParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const FrameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 0px 28px;
`;
const IconsetChild = styled.img`
  height: 43px;
  width: 43px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
`;
const JohnJacob = styled.a`
  text-decoration: none;
  align-self: stretch;
  position: relative;
  font-family: var(--font-geist);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 28px;
  color: inherit;
`;
const Text2 = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  min-width: 119px;
  white-space: nowrap;
`;
const Text3 = styled.div`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Icontext3 = styled.div`
  width: 178px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px 0px 0px;
  box-sizing: border-box;
  gap: 16px 14px;
`;
const Content = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px 16px;
`;
const Name1 = styled.div`
  align-self: stretch;
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
`;
const Text4 = styled.div`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 335px;
  max-width: 100%;
`;
const JohnJacob1 = styled.a`
  text-decoration: none;
  position: relative;
  font-family: var(--FontfamilyInter);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  color: inherit;
  display: inline-block;
  min-width: 77px;
`;
const ArrowlinerightIcon = styled.img`
  height: 20px;
  width: 20px;
  position: relative;
`;
const Icontext4 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px 0px 0px;
  gap: 4px 2px;
  color: ${({ theme }) => theme.font.minorText4};
`;
const Content1 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: 16px 14px;
  max-width: 100%;
  font-size: 14px;
`;
const ContentParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  max-width: 100%;
`;
const Text5 = styled.div`
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
`;
const Text6 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 12px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 18px;
`;
const Johnyahoocom = styled.div`
  position: relative;
  font-family: var(--FontfamilyInter);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  min-width: 119px;
  white-space: nowrap;
`;
const Content2 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: 16px 14px;
  max-width: 100%;
`;
const Text7 = styled.div`
  align-self: stretch;
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-xs);
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.font.minorText4};
`;
const Text8 = styled.div`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 335px;
  max-width: 100%;
`;
const Content3 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundColors.myAccount};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: 16px 14px;
  max-width: 100%;
`;
const TextParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
  font-size: 14px;
`;
const FrameGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  min-width: 551px;
  max-width: 100%;
  font-size: 18px;
  font-family: Inter;
  @media screen and (max-width: 675px) {
    min-width: 100%;
  }
  @media screen and (max-width: 450px) {
    gap: 16px;
  }
`;
const FrameParent = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 54px;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: ${({ theme }) => theme.font.minorText3};
  font-family: Geist;
  @media screen and (max-width: 625px) {
    gap: 27px;
  }
`;
const SettingPopupRoot = styled.div`
  width: 1176px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.backgroundColors.body};
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 37px 31px 319px;
  box-sizing: border-box;
  gap: 5px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: Geist;
`;
const UploadFileInput = styled.input`
  display: none;
  cursor: pointer;
`;
const SettingPopup = ({ className = "" }) => {
  const [file, setFile] = useState(null);
  // const [userImg, setUserImg] = useState("/images/membericon.png");
  const { userDetails, userImg, setUserImg } = useContext(userContext);
  const theme = useTheme();

  const profileIcon = theme.images.myAccProfileIcon;
  const supportIcon = theme.images.myAccSupportIcon;
  const ModelSelectionIcon = theme.images.myAccModelSelectIcon;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log("File selected:", selectedFile);
    uploadProfilePic(selectedFile);
  };

  const getProfilePic = async () => {
    try {
      const url = "http://129.146.99.175:4000/api/users/getuserbytoken";
      const OPTIONS = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: userDetails?.userToken,
        }),
      };
      const response = await fetch(url, OPTIONS);
      const data = await response.json();
      console.log(data);
      const profilePicUrl = data?.user?.profile_url || "/images/membericon.png"; // Fallback to default image
      setUserImg(profilePicUrl);
    } catch (error) {
      console.log(error);
      setUserImg("/images/membericon.png"); // Fallback to default image on error
    }
  };

  const uploadProfilePic = async (selectedFile) => {
    try {
      const token = userDetails?.userToken;
      const url = `http://129.146.99.175:4000/api/minio/uploadprofilepic?token=${token}`;

      const fileData = new FormData();
      fileData.append("file", selectedFile);
      const OPTIONS = {
        method: "POST",
        body: fileData,
      };
      const response = await fetch(url, OPTIONS);
      const data = await response.json();
      console.log(data);
      // setUserImg(data.profileUrl);
      getProfilePic();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    getProfilePic();
  }, []);
  return (
    <SettingPopupRoot className={className}>
      <MyAccountWrapper>
        <MyAccount>My Account</MyAccount>
      </MyAccountWrapper>
      <FrameParent>
        <FrameWrapper>
          <ProfileParent>
            <Profile1>
              <Icontext>
                <Iconset>
                  <ByewindIcon loading="lazy" alt="" src={profileIcon} />
                </Iconset>
                <Text1>
                  <Profile>Profile</Profile>
                </Text1>
              </Icontext>
            </Profile1>
            <Suppport>
              <Icontext1>
                <Iconset>
                  <PaletteIcon loading="lazy" alt="" src={supportIcon} />
                </Iconset>
                <Text1>
                  <Support>Support</Support>
                </Text1>
              </Icontext1>
            </Suppport>
            <Suppport>
              <Icontext2>
                <Iconset>
                  <PaletteIcon
                    loading="lazy"
                    alt="Model Selection Icon"
                    src={ModelSelectionIcon}
                  />
                </Iconset>
                <Text1>
                  <ModelSelection>Model Selection</ModelSelection>
                </Text1>
              </Icontext2>
            </Suppport>
          </ProfileParent>
        </FrameWrapper>
        <FrameGroup>
          <ContentParent>
            <Content>
              <Icontext3>
                <UploadFileInput
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  accept="image/*"
                  onChange={handleFileChange}
                />

                <Iconset onClick={handleClick} title="Upload Photo">
                  <IconsetChild
                    loading="lazy"
                    alt="profilephoto"
                    src={userImg}
                  />
                </Iconset>
                <Text3>
                  <JohnJacob>{userDetails?.userName}</JohnJacob>
                  <Text2>{userDetails?.userEmailId}</Text2>
                </Text3>
              </Icontext3>
            </Content>
            <Content1>
              <Text4>
                <Name1>Name</Name1>
              </Text4>
              <Icontext4>
                <Text1>
                  <JohnJacob1>{userDetails?.userName}</JohnJacob1>
                </Text1>
                <Iconset>
                  <ArrowlinerightIcon
                    loading="lazy"
                    alt=""
                    src="images/arrowlineright.svg"
                  />
                </Iconset>
              </Icontext4>
            </Content1>
          </ContentParent>
          <TextParent>
            <Text6>
              <Text5>Account security</Text5>
            </Text6>
            <Content2>
              <Text4>
                <Name1>Email</Name1>
              </Text4>
              <Icontext4>
                <Text1>
                  <Johnyahoocom>{userDetails?.userEmailId}</Johnyahoocom>
                </Text1>
                <Iconset>
                  <ArrowlinerightIcon
                    loading="lazy"
                    alt=""
                    src="images/arrowlineright.svg"
                  />
                </Iconset>
              </Icontext4>
            </Content2>
            <Content3>
              <Text8>
                <Name1>Password</Name1>
                <Text7>
                  Set a permanent password to login to your account.
                </Text7>
              </Text8>
              <Iconset>
                <ArrowlinerightIcon
                  loading="lazy"
                  alt=""
                  src="images/arrowlineright.svg"
                />
              </Iconset>
            </Content3>
          </TextParent>
        </FrameGroup>
      </FrameParent>
    </SettingPopupRoot>
  );
};

SettingPopup.propTypes = {
  className: PropTypes.string,
};

export default SettingPopup;
