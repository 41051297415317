import styled from "styled-components";
import PropTypes from "prop-types";

const Email = styled.div`
  width: 390px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
  font-family: var(--font-geist);
  font-weight: 400;
  font-size: var(--font-size-base);
  line-height: 19.84px;
`;
const FrameChild = styled.div`
  height: 59px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  border: 0.6px solid #282828;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const EnterYourEmail = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 300;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 17.36px;
  background-color: transparent;
  height: 20px;
  color: ${({ theme }) => theme.font.primary};
  text-align: left;
  display: inline-block;
  padding: 0;
  z-index: 1;
  width: ${(p) => p.propWidth};
`;
const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  border: ${({ theme }) => theme.border.secondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 27px;
  max-width: 100%;
  z-index: 1;
`;
const InputTrioRoot = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 7px;
  box-sizing: border-box;
  gap: 13px;
  max-width: 100%;
  text-align: left;
  font-size: 17px;
  color: ${({ theme }) => theme.font.primary};
  font-family: Geist;
`;

const ErrorMsg = styled.div`
  font-size: var(--font-size-xs);
  color: var(--color-error);
`;

const InputTrio1 = ({
  className = "",
  email,
  enterYourEmailPlaceholder,
  propWidth,
  onChange,
  name,
  value,
  loginErrors,
  onFocus,
}) => {
  return (
    <InputTrioRoot className={className}>
      <Email>{email}</Email>
      <RectangleParent>
        <FrameChild />
        <EnterYourEmail
          placeholder={enterYourEmailPlaceholder}
          type="text"
          propWidth={propWidth}
          onChange={onChange}
          name={name}
          value={value}
          onFocus={onFocus}
          autoComplete="off"
        />
      </RectangleParent>
      <ErrorMsg>{loginErrors}</ErrorMsg>
    </InputTrioRoot>
  );
};

InputTrio1.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  enterYourEmailPlaceholder: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
};

export default InputTrio1;
