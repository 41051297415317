import styled from "styled-components";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import usePasswordVisibility from "../Hooks/usePasswordVisibility";
import EyeIconToggler from "../EyeIconToggler/eyeIconToggler";

const Password = styled.div`
font-family: var(--font-geist);
  font-weight:400;
  font-size: var(--font-size-base);
  line-height:19.84px
  width: 390px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
`;
const FrameChild = styled.div`
  height: 59px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  border: 0.6px solid #282828;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const FrameItem = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0px 0px;
  box-sizing: border-box;
  font-weight: 300;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  line-height: 17.36px;
  color: ${({ theme }) => theme.font.primary};
  width: ${(p) => p.propWidth1};
`;

const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: 6px;
  background-color: transparent;
  border: ${({ theme }) => theme.border.secondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 18px 28px 18px 27px;
  max-width: 100%;
  gap: 20px;
  z-index: 1;
`;
const InputTrioRoot = styled.div`width: 423px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 7px;
  box-sizing: border-box;
  gap: 9px;
  max-width: 100%;
  text-align: left;
  font-size: 17px;
  color: ${({ theme }) => theme.font.primary};
  font-family: Geist;
  width: ${(p) => p.propWidth}
  padding: ${(p) => p.propPadding}
  align-self: ${(p) => p.propAlignSelf}
`;

const InputTrio = ({
  className = "",
  password,
  frame9Placeholder,
  propWidth,
  propPadding,
  propAlignSelf,
  propWidth1,
  show,
  setShow,
  theme,
  value,
  onChange,
  name,
}) => {
  const { showPassword, handleShowPassword } = usePasswordVisibility();

  return (
    <InputTrioRoot
      propWidth={propWidth}
      propPadding={propPadding}
      propAlignSelf={propAlignSelf}
      className={className}
      theme={theme}
    >
      <Password>{password}</Password>
      <RectangleParent>
        <FrameChild />
        <FrameItem
          placeholder={frame9Placeholder}
          type={showPassword ? "text" : "password"}
          propWidth1={propWidth1}
          value={value}
          onChange={onChange}
          name={name}
          autoComplete="off"
        />
        <EyeIconToggler
          showPassword={showPassword}
          handleShowPassword={handleShowPassword}
        />
      </RectangleParent>
    </InputTrioRoot>
  );
};

InputTrio.propTypes = {
  className: PropTypes.string,
  password: PropTypes.string,
  frame9Placeholder: PropTypes.string,
  propWidth: PropTypes.any,
  propPadding: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propWidth1: PropTypes.any,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputTrio;
