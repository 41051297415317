import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import Sidebar from "../components/Sidebar/Sidebar";
// import FrameComponent from "../components/FrameComponent";

const Iconset = styled.div`
  height: 16.3px;
  width: 14.4px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Button = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;


const Icontext = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 556px 0px 0px;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
  @media screen and (max-width: 750px) {
    padding-right: 278px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-right: 20px;
    box-sizing: border-box;
  }
`;
const PaperplanerightIcon = styled.img`
  height: 20px;
  width: 20px;
  position: relative;
`;
const Iconset1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Input = styled.div`
  border-radius: 16px;
  background-color: #f7f9fb;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15.9px 20px 15.8px;
  box-sizing: border-box;
  max-width: 100%;
`;

const PreviousChatsOn = styled.div`
  position: relative;
  line-height: 17px;
  font-weight: 500;
`;
const FrameItem = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  margin-top: -1px;
`;
const VectorWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9.5px 0px 0px;
  box-sizing: border-box;
  min-width: 341px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`;
const PreviousChatsOnDateParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  max-width: 100%;
`;
const FrameInner = styled.div`
  height: 55px;
  width: 702px;
  position: relative;
  border-radius: 12px;
  background-color: #f7f9fb;
  display: none;
  max-width: 100%;
`;
const PromptToKashiKa = styled.div`
  position: relative;
  line-height: 120%;
  z-index: 1;
`;
const UserMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0px 0px;
`;
const RectangleDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 26px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: none;
`;
const RectangleWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 26px;
  background-color: #fff;
  width: 100%;
  height: 100%;
`;
const MdirenameIcon = styled.img`
  position: absolute;
  top: 2px;
  left: 1px;
  width: 20px;
  height: 22px;
  overflow: hidden;
  z-index: 1;
`;
const FrameContainer = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
`;

const VectorIcon2 = styled.img`
  position: absolute;
  height: 41.67%;
  width: 37.5%;
  top: 29.17%;
  right: 29.17%;
  bottom: 29.17%;
  left: 33.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
`;
const UserMessageIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: 12px;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 33px 15px 24px;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
  font-size: 16px;
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
`;
const BotMessageReplyContent = styled.div`
  flex: 1;
  border-radius: 12px;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 33px 15px 24px;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
`;
const BotMessageReplyContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 5px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 16px;
`;
const YesterdayUserMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0px 0px;
`;
const YesterdayUserMessage = styled.div`
  flex: 1;
  border-radius: 12px;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 13px 33px 17px 24px;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
`;
const YesterdayChatContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px 4px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 16px;
`;
const PreviousChatsOnYesterdayParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
  max-width: 100%;
`;
const ChatHistoryContent = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 27px;
  max-width: 100%;
`;
const ChatHistoryContentWrapper = styled.div`
  width: 1134px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  max-width: 100%;
  color: #52555d;
`;

const VectorIcon11 = styled.img`
  position: absolute;
  height: 22px;
  width: 16px;
  top: 2px;
  right: 4px;
  bottom: 4px;
  left: 4.5px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
`;


const VectorIcon1 = styled.img`
  height: 16.3px;
  width: 14.4px;
  position: relative;
`;

const Group = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Text5 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Content1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px 0px 0px;
  box-sizing: border-box;
  gap: 8px 6px;
  min-height: 24px;
  max-width: 100%;
`;

const InputBox = styled.footer`
  align-self: stretch;
  height: 116px;
  backdrop-filter: blur(100px);
  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    rgba(255, 255, 255, 0.8);
  display: flex;
  // padding:20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 15px;
  color: rgba(28, 28, 28, 0.2);
  font-family: Geist;
  // margin-top:20px;
  // margin-left:40px;

//   @media screen and (max-width: 1200px) {
//      padding:120px;
//   }
// `;

const InnerContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 274px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    gap: 137px;
  }
  @media screen and (max-width: 750px) {
    gap: 68px;
  }
  @media screen and (max-width: 450px) {
    gap: 34px;
  }
`;
const InnerContentWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 205px 0px 0px ;
  box-sizing: border-box;
  height: 100vh;
  max-width: calc(100% - 485px);
  text-align: left;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Geist;

  // @media screen and (max-width: 1496px) {
  //   padding: 187px;
  // }

  @media screen and (max-width: 1050px) {
    padding-top: 187px;
    box-sizing: border-box;
    max-width: 100%;
  }
  @media screen and (max-width: 750px) {
    padding-top: 122px;
    box-sizing: border-box;
  }
`;

const InputField = styled.input`
  border: none;
  outline: none;
  background-color: #f7f9fb;
  border-radius: 8px;
  padding: 4px 8px;
  box-sizing: border-box;
  font-family: Geist;
  font-size: 14px;
  color: #1c1c1c;
  width: 100%;
`;

const RightPanel = styled.main`
  width: 1663px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 0px 20px 0px 0px;
  box-sizing: border-box;
  gap: 182px;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    gap: 91px;
  }

  @media screen and (max-width: 1050px) {
    padding-left: 20px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 750px) {
    gap: 45px;
  }
  @media screen and (max-width: 450px) {
    gap: 23px;
  }
`;
const ChatContentInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0px 0px;
`;
const GroupIcon = styled.img`
  height: 36.9px;
  width: 36.9px;
  position: relative;
  flex-shrink: 0;
`;
const FrameChild = styled.img`
  align-self: stretch;
  height: 29.2px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;
const ChatContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 27px 0px 26px;
`;
const PreviousChats = styled.h1`
  margin-bottom: 20px;
  position: relative;
  font-size: inherit;
  line-height: 90%;
  font-weight: 600;
  font-family: inherit;
  @media screen and (max-width: 1050px) {
    font-size: 26px;
    line-height: 31px;
  }
  @media screen and (max-width: 450px) {
    font-size: 19px;
    line-height: 23px;
  }
`;
const FrameGroup = styled.div`
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // gap: 2.1px;
`;
const ChatContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 19.3px;
`;
const FrameWrapper = styled.div`
  width: 1133px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px 5px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 32px;
`;
const NewchatRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 64px 0px 0px;
  box-sizing: border-box;
  gap: 13.9px;
  line-height: normal;
  letter-spacing: normal;
  height: 100vh; /* Set the height to full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const Newchat = () => {
  const [theme, setTheme] = useState(lightTheme);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === lightTheme ? darkTheme : lightTheme));
  };

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    // Handle the message send logic here
    console.log("Message sent:", message);
    setMessage(""); // Clear the input field
  };

  return (
    <>
    <ThemeProvider theme={theme}>
    <NewchatRoot>
     <RightPanel>
      <Sidebar onToggleTheme={toggleTheme} />
      <InnerContentWrapper>
      <FrameWrapper>
        <FrameGroup>
          <ChatContentWrapper>
            <ChatContent>
              <GroupIcon loading="lazy" alt="" src="images/groupicon-light.svg" />
              <ChatContentInner>
                <FrameChild loading="lazy" alt="" src="images/kashikatext-light.svg" />
              </ChatContentInner>
            </ChatContent>
          </ChatContentWrapper>
          <PreviousChats>Previous Chats</PreviousChats>
        </FrameGroup>
      </FrameWrapper>
        <InputBox>
              <Input>
                <Content1>
                  <Icontext>
                    <Group>
                      <Button>
                        <Iconset>
                          <VectorIcon1 alt="" src="/images/upload.png" />
                        </Iconset>
                      </Button>
                      </Group>
                    <Text5>
                      <InputField
                        type="text"
                        placeholder="Type message"
                        value={message}
                        onChange={handleInputChange}
                      />
                    </Text5> 
                    </Icontext>
                  <Iconset1 onClick={handleSendMessage}>
                    <PaperplanerightIcon alt="" src="/images/send.png" />
                  </Iconset1>
                </Content1>
              </Input>
            </InputBox> 
          <InnerContent>
          <ChatHistoryContentWrapper>
        <ChatHistoryContent>
          <PreviousChatsOnDateParent>
            <PreviousChatsOn>Previous chats on Date</PreviousChatsOn>
            <VectorWrapper>
              <FrameItem loading="lazy" alt="" src="/images/line 5.png" />
            </VectorWrapper>
          </PreviousChatsOnDateParent>
          <RectangleParent>
            <FrameInner />
            <UserMessageContent>
              <PromptToKashiKa>Prompt to Kashi-ka</PromptToKashiKa>
            </UserMessageContent>
            <UserMessageIcons>
              <FrameContainer>
                <RectangleWrapper>
                  <RectangleDiv />
                </RectangleWrapper>
                <MdirenameIcon loading="lazy" alt="" src="./images/Group 25.png" />
              </FrameContainer>
              <FrameContainer>
                <RectangleWrapper>
                  <RectangleDiv />
                </RectangleWrapper>
                <VectorIcon11 loading="lazy" alt="" src="./images/Group 26.png" />
              </FrameContainer>
              <FrameContainer>
                <RectangleWrapper>
                  <RectangleDiv />
                </RectangleWrapper>
                <VectorIcon2 loading="lazy" alt="" src="./images/Vector.png" />
              </FrameContainer>
            </UserMessageIcons>
          </RectangleParent>
          <BotMessageReplyContentWrapper>
            <BotMessageReplyContent>
              <FrameInner />
              <UserMessageContent>
                <PromptToKashiKa>Prompt to Kashi-ka</PromptToKashiKa>
              </UserMessageContent>
              <UserMessageIcons>
                <FrameContainer>
                  <RectangleWrapper>
                    <RectangleDiv />
                  </RectangleWrapper>
                  <MdirenameIcon loading="lazy" alt="" src="./images/Group 25.png" />
                </FrameContainer>
                <FrameContainer>
                  <RectangleWrapper>
                    <RectangleDiv />
                  </RectangleWrapper>
                  <VectorIcon11 loading="lazy" alt="" src="./images/Group 26.png" />
                </FrameContainer>
                <FrameContainer>
                  <RectangleWrapper>
                    <RectangleDiv />
                  </RectangleWrapper>
                  <VectorIcon2 loading="lazy" alt="" src="./images/Vector.png" />
                </FrameContainer>
              </UserMessageIcons>
            </BotMessageReplyContent>
          </BotMessageReplyContentWrapper>
          <PreviousChatsOnYesterdayParent>
            <PreviousChatsOn>Previous chats on Yesterday</PreviousChatsOn>
            <YesterdayChatContent>
              <YesterdayUserMessage>
                <FrameInner />
                <YesterdayUserMessageContent>
                  <PromptToKashiKa>Prompt to Kashi-ka</PromptToKashiKa>
                </YesterdayUserMessageContent>
                <UserMessageIcons>
                  <FrameContainer>
                    <RectangleWrapper>
                      <RectangleDiv />
                    </RectangleWrapper>
                    <MdirenameIcon
                      loading="lazy"
                      alt=""
                      src="./images/Group 25.png"
                    />
                  </FrameContainer>
                  <FrameContainer>
                    <RectangleWrapper>
                      <RectangleDiv />
                    </RectangleWrapper>
                    <VectorIcon11 loading="lazy" alt="" src="./images/Group 26.png" />
                  </FrameContainer>
                  <FrameContainer>
                    <RectangleWrapper>
                      <RectangleDiv />
                    </RectangleWrapper>
                    <VectorIcon2 loading="lazy" alt="" src="./images/Vector.png" />
                  </FrameContainer>
                </UserMessageIcons>
              </YesterdayUserMessage>
            </YesterdayChatContent>
          </PreviousChatsOnYesterdayParent>
        </ChatHistoryContent>
      </ChatHistoryContentWrapper>
          </InnerContent>
        </InnerContentWrapper>
      </RightPanel>
    </NewchatRoot>
    </ThemeProvider>
    </>
    
  );
};

export default Newchat;