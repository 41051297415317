import styled from "styled-components";
import { useTheme } from "styled-components";

const EyeIcon = styled.img`
  width: 21px;
  height: 18.13px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 21px;
  z-index: 2;
`;

const EyeIconToggler = ({ showPassword, handleShowPassword }) => {
  // console.log(showPassword, handleShowPassword);
  const theme = useTheme();

  const eyeIcon = showPassword
    ? theme.images.eyeIconVisible
    : theme.images.eyeIconInvisible;

  return (
    <EyeIcon
      src={eyeIcon}
      onClick={handleShowPassword}
      alt="Toggle Password Visibility"
    />
  );
};

export default EyeIconToggler;
