import styled from "styled-components";
import PropTypes from "prop-types";

const ChatsIcon = styled.img`
  height: 32px;
  width: 32px;
  position: relative;
`;
const Iconset = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IconsetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 27px 0px 26px;
`;

const ChatListItem = styled.div`
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 85px;
`;
const Text1 = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ContentRoot = styled.div`
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
  gap: 8px;
  text-align: left;
  font-size: 18px;
  color: #1c1c1c;
  font-family: Geist;
`;

const Content = ({ className = "", chats, chatListItemName }) => {
  return (
    <ContentRoot className={className}>
      <IconsetWrapper>
        <Iconset>
          <ChatsIcon loading="lazy" alt="" src={chats} />
        </Iconset>
      </IconsetWrapper>
      <Text1>
        <ChatListItem>{chatListItemName}</ChatListItem>
      </Text1>
    </ContentRoot>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  chats: PropTypes.string,
  chatListItemName: PropTypes.string,
};

export default Content;