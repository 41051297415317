import styled from "styled-components";
import InputTrio1 from "./email-input";
// import InputTrio from "./password-input";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { passwordResetContext } from "../Contexts/passwordResetContext";
import { useContext } from "react";

const FrameChild = styled.div`
  width: 505px;
  height: 757px;
  position: relative;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #fff;
  border: 0.5px solid #878787;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
`;
const EmailTitle = styled.h2`
  margin: 0;
  align-self: center;
  position: relative;
  margin-top: 50px;
  line-height: 32px;
  font-weight: 600;
  font-family: var(--font-geist);
  font-size: 1.5rem;
  color: ${({ theme }) => theme.font.primary};
  @media screen and (max-width: 450px) {
    font-size: 19px;
    line-height: 26px;
  }
  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    ont-size: 14px;
    padding-right: 33px;
  }
  @media screen and (max-width: 375px) {
    font-size: 13px;
  }
  @media screen and (max-width: 360px) {
    font-size: 10px;
  }
  @media screen and (max-width: 414px) {
    font-size: 12px;
  }
`;

const EmailSubtitle = styled.div`
  position: relative;
  font-weight: 400;
  font-family: var(--font-geist);
  line-height: 20px;
  color: ${({ theme }) => theme.font.minorText4};
  align-self: center;
  font-size: var(--font-size-sm);
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
`;
const FrameItem = styled.div`
  height: 57px;
  width: 423px;
  position: relative;
  border-radius: 6px;
  background-color: #000;

  display: none;
  max-width: 100%;
`;
const Send = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 19.84px;
  color: #ffffff;
  display: inline-block;
  min-width: 65px;
  z-index: 1;
`;
const RectangleGroup = styled.button`
  cursor: pointer;
  border: none;
  padding: 17px 20px 19px;
  background-color: ${({ theme }) => theme.backgroundColors.buttonColor};
  border-radius: 6px;
  width: 100%; /* Ensure button takes full width of parent */
  max-width: 423px; /* Limit max-width if needed */
  box-sizing: border-box;
  text-align: center; /* Center text */
  &:hover {
    background-color: #333;
  }

  @media screen and (max-width: 768px) {
    padding: 17px 10px 19px;
  }
`;
const SendButtonParent = styled.div`
  width: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 3px;
  box-sizing: border-box;
  gap: 31px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 15px;
  }
  @media screen and (max-width: 1024px) {
    width: 334px;
  }
  @media screen and (max-width: 912px) {
    width: 285px;
  }
`;

const Back = styled.div`
  position: relative;
  font-family: var(--font-geist);
  font-size: var(--font-size-sm);
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  min-width: 33px;
  color: ${({ theme }) => theme.font.minorText5};
`;
const Text2 = styled.div`
  border-radius: var(--boundvariablesdata2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
`;
const TextContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px var(--padding-xl) 0px 22px;
  font-size: var(--regular-size);
  color: #0000ff;
  font-family: var(--regular);
`;
const RectangleParentRoot = styled.form`
  margin: 0;
  width: 50%;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
  border-radius: 33px;
  background-color: ${({ theme }) => theme.backgroundColors.bodySecondary};
  border: 0.6px solid var(--color-gray-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  padding: 14px 10px 152px 10px;
  gap: 17px;
  max-width: 100%;
  @media screen and (max-width: 320px) {
    padding: 20px 0px 87px 0px;
  }
  @media screen and (max-width: 344px) {
    width: 100% !important;
  }
  @media screen and (max-width: 768px) {
    width: 75%;
    padding: 13px 20px 27px;
  }
  @media screen and (max-width: 853px) {
    padding: 14px 35px 152px 40px;
    width: 78%;
  }

  @media screen and (max-width: 414px) {
    padding: 14px 35px 54px 40px;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const GroupComponent = ({ className = "" }) => {
  const { userPasswordReset, setUserPasswordReset } =
    useContext(passwordResetContext);
  console.log(userPasswordReset);
  const navigate = useNavigate();

  const handlePostData = async (event) => {
    event.preventDefault();

    console.log("User data:", userPasswordReset?.passwordResetEmail);
    const url = "http://129.146.99.175:4000/api/users/sendForgotPasswordEmail";
    const email = userPasswordReset.passwordResetEmail;
    const OPTIONS = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };

    try {
      const response = await fetch(url, OPTIONS);

      if (!response.ok) {
        console.error("Error:", response.status, response.statusText);
        return;
      }
      const data = await response.json();
      console.log("Response data from password email:", data);
      setUserPasswordReset((prev) => ({
        ...prev,
        passwordResetToken: data.emailToken,
      }));
      navigate("/verification");
      // setUserPasswordReset((prevUser) => ({
      //   ...prevUser,
      //   passwordResetEmail: "",
      // }));
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    console.log("Event value:", name, value); // Log the event value
    setUserPasswordReset((prevUser) => ({
      ...prevUser,
      passwordResetEmail: value,
    }));
  };

  return (
    <RectangleParentRoot className={className}>
      <FrameChild />
      <EmailTitle>Forgot Password ?</EmailTitle>
      <EmailSubtitle>Enter your email to reset your password.</EmailSubtitle>
      <InputTrio1
        email="Email"
        enterYourEmailPlaceholder="Enter your email"
        name="email"
        value={userPasswordReset?.passwordResetEmail}
        onChange={handleOnChange}
      />

      <SendButtonParent>
        <RectangleGroup onClick={handlePostData}>
          <FrameItem />
          <Send>Send</Send>
        </RectangleGroup>
      </SendButtonParent>
      <TextContainer>
        <Text2>
          <Link to="/">
            <Back> Back</Back>
          </Link>
        </Text2>
      </TextContainer>
    </RectangleParentRoot>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent;
